import productService from '@/service/product';
import Const from '@/utils/const';
import _ from 'lodash';
import { useStorage } from '@vueuse/core';
import { ref } from 'vue';
let platformType = ref(null);
let platformServer = ref(null);
const dependPlatformType = function (callBack) {
  if (platformType.value === null) {
    productService.getPlatformType().then(res => {
      platformType.value = res.platformType ? res.platformType : 'standard';
      platformServer.value = res.platformServer ? res.platformServer : 'cloud';
      if (callBack) {
        callBack();
      }
    });
  } else {
    if (callBack) {
      callBack();
    }
  }
};
let products = {
  product: {
    "name": Kf.t('KingfisherClouds'),
    "title": Kf.t('KingfisherClouds'),
    "icon": "/static/favicon.ico",
    "logo": "/img/kingfisher_logo.png",
    "loading": "/img/kf-loadding.png",
    "miniLogo": "/img/kfMinLogo.png",
    "bg": "/img/kingfisher_login_bg.png",
    "enTitle": "KINGFISHER CLOUD " + new Date().getFullYear()
  },
  "3dc": {
    "name": Kf.t('3dConfigurationTool'),
    "title": Kf.t('KingfisherCloud3dConfigurationTool'),
    "logo": "/img/logo.png",
    "loading": "/img/kf-loadding.png",
    "bg": "/img/kingfisher_login_bg.png",
    "enTitle": "Kingfisher Cloud - 3D Configuration Tool"
  },
  "configuration": {
    "name": Kf.t('2dConfigurationTool'),
    "title": Kf.t('KingfisherCloud2dConfigurationTool'),
    "logo": "/img/logo.png",
    "loading": "/img/kf-loadding.png",
    "bg": "/img/kingfisher_login_bg.png",
    "enTitle": "Kingfisher Cloud - 2D Configuration Tool",
    "loginPage": "/cloud/configuration/index.html"
  },
  "kcmarket": {
    "name": Kf.t('ComponentMarket'),
    "title": Kf.t('KingfisherCloudComponentMarket'),
    "logo": "/img/logo.png",
    "loading": "/img/kf-loadding.png",
    "bg": "/ui/img/kingfisher_login_bg.png",
    "enTitle": "Kingfisher Cloud - Component Market"
  },
  'space': {
    "name": Kf.t('SpatialGovernanceTool'),
    "title": Kf.t('KingfisherCloudSpaceGovernanceTool'),
    "logo": "/img/logo.png",
    "loading": "/img/kf-loadding.png",
    "bg": "/img/kingfisher_login_bg.png",
    "enTitle": "Kingfisher Cloud - Space Management Tool",
    "loginPage": "/cloud/space/index.html"
  },
  'saasspace': {
    "name": Kf.t('SpatialGovernanceTool'),
    "title": Kf.t('KingfisherCloudSpaceGovernanceTool'),
    "logo": "/img/logo.png",
    "loading": "/img/kf-loadding.png",
    "bg": "/img/kingfisher_login_bg.png",
    "enTitle": "Kingfisher Cloud - Space Management Tool",
    "loginPage": "/cloud/space/index.html"
  }
};
const storedProductInfo = useStorage("KC_PRODUCT_INFO", {});
let serverSetting = Object.assign({}, _.cloneDeep(products));
if (storedProductInfo.value) {
  Object.keys(storedProductInfo.value).map(key => {
    if (serverSetting[key]) {
      Object.assign(serverSetting[key], storedProductInfo.value[key]);
    }
  });
}
storedProductInfo.value = serverSetting;
serverSetting.loaded = false;
const init = function (callback) {
  if (!serverSetting.loaded) {
    if (!serverSetting.product) {
      serverSetting = Object.assign(serverSetting, _.cloneDeep(products));
    }
    productService.getProductInfo().then(result => {
      serverSetting.loaded = true;
      Object.keys(serverSetting).map(key => {
        if (result[key]) {
          serverSetting[key] = Object.assign(serverSetting[key], result[key]);
        }
      });
      storedProductInfo.value = _.cloneDeep(serverSetting);
      if (callback) {
        callback();
      }
    });
  } else {
    if (callback) {
      callback();
    }
  }
};
const getImage = function (product, item, context) {
  if (!serverSetting.loaded) {
    init();
  }
  if (Const.develop) {
    return serverSetting[product][item];
  } else if (product !== 'kcmarket') {
    return '/cloud/' + context + serverSetting[product][item];
  } else {
    return '/' + context + serverSetting[product][item];
  }
};
const beSame = function (product, item) {
  if (!serverSetting.loaded) {
    init();
  }
  return products[product][item] === serverSetting[product][item];
};
export default {
  products: serverSetting,
  init,
  getImage,
  beSame,
  platformType,
  platformServer,
  dependPlatformType
};