import { createRouter, createWebHashHistory } from 'vue-router';
const main = () => import('../components/main.vue');
const routes = [{
  path: '/',
  name: "/",
  component: () => import('../components/Login')
}, {
  path: "/content",
  name: "/content",
  component: () => import("../components/newContent/newContent.vue")
}, {
  path: "/content/:projectUuid",
  name: "/content-projectId",
  component: () => import("../components/newContent/newContent.vue")
}, {
  path: '/main',
  name: "/main",
  component: main
}, {
  path: '/main/:projectUuid/:buildingUuId/:ticket',
  name: "/main-projectId-buildingId-ticket",
  component: main
}, {
  path: '/:pathMatch',
  component: () => import("../components/notFound.vue")
}];
const router = createRouter({
  history: createWebHashHistory(),
  routes
});
router.isRuntime = true;
export default router;