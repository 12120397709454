import i18n from './i18n';
import ElementPlus from 'element-plus';
import Router from './router/BUILD_TARGET.js';
//import 'element-plus/lib/theme-chalk/index.css'
import * as Icons from '@element-plus/icons';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/dist/index.css';
import { createApp } from 'vue';
import App from './App.vue';
import VueClipboard from 'vue3-clipboard';
import vueSeamlessScroll from 'vue-seamless-scroll/src';
window.kfApp = createApp(App).use(ElementPlus, {
  locale: zhCn
}).use(Router).use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true
}).use(i18n).use(vueSeamlessScroll);

/*const  store=new Vuex.Store({
  modules: {
    main: {
      namespaced: true,
      state,
      /!*    actions,*!/
      /!*   getters,*!/
      mutations,
      /!* plugins:[createLogger]*!/
    },

  }

});
window.kfApp.use(store)*/

/*if (process.env.NODE_ENV === 'development') {
    if ('__VUE_DEVTOOLS_GLOBAL_HOOK__' in window) {
        window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = kingfisher
    }
    kingfisher.config.devtools = true
}*/
window.addEventListener('keydown', function (e) {
  if (e.keyCode == 83 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) || e.code === 'KeyR' && e.ctrlKey || e.code === 'F5') {
    e.preventDefault();
  }
});
Object.keys(Icons).forEach(key => {
  window.kfApp.component(key, Icons[key]);
});
window.kfApp.config.errorHandler = function (err, vm, info) {
  console.error(err, vm, info);
  return false;
};
window.kfApp.mount('#app');
window.router = Router;