import { ref } from 'vue';
const develop = 'development' === process.env.NODE_ENV;
let host = window.location.href;
host = host.substring(0, host.indexOf("/", 8));
const server = host + '/configuration/v2/api-docs';
const wxSysCode = "kfConfiguration";
const contextPath = "/3dc/";
let servicePath = ref('/cloud');
export default {
  develop,
  server,
  wxSysCode,
  servicePath,
  contextPath
};