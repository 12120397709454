const en = {
  language: {
    "zh-CN": '简体中文',
    "zh-TW": '繁体中文',
    "en": 'English',
  },
  '2dConfigurationTool': '2D Configuration Tool',
  '3d': '3D',
  '3dConfigurationTool': '3D Configuration Tool',
  '3dDesigner': '3D Designer',
  '3dDisplay': '3D Display ',
  '?': '?',
  'About': 'About',
  'AboutUs': 'About Us',
  'ABuildingWithTheSameNameAlreadyExists': 'A building with the same name already exists',
  'Add': 'Add',
  'AddAttribute': 'Add Attribute ',
  'AddDrawing': 'Add Drawing',
  'AddNew': 'Add New',
  'AddPosition': 'Add Position ',
  'AddSelection': 'Add Selection ',
  'AddStatus': 'Add Status',
  'AdvancedOutlineSettings': 'Advanced Outline Settings',
  'AdvancedSettings': 'Advanced Settings',
  'Alignment': 'Alignment',
  'AlignUp': 'Align Up',
  'All': 'All',
  'AllWidgets': 'All Widgets',
  'AllowClickUser': 'Allow Click User ',
  'AllowMovingToGroup': 'Allow moving to group',
  'AllowPropertySettings': 'Allow Property Settings',
  'AlongXAxis': 'Along X Axis',
  'AlongYaxis': 'Along Y-Axis',
  'AlreadyAdded': 'Already Added',
  'AmbientOcclusion': 'Ambient Occlusion ',
  'AModelThatWasBoundBeforeTheBindingWasPerformed': 'A model that was bound before the binding was performed',
  'Angle': 'Angle',
  'AnotherUserLoggedInWithTheSameAccount': 'Another user logged in with the same account ',
  'Appearances': 'Appearances',
  'Area': 'Area',
  'AreYouSure': 'Are you sure?',
  'AreYouSureItsDeleted': 'Are you sure its deleted?',
  'AreYouSureYouWantToDeleteIt': 'Are you sure you want to delete it?',
  'AreYouSureYouWantToDeleteThisData': 'Are you sure you want to delete this data?',
  'Arrange': 'Arrange',
  'AttemptMapping': 'Attempt Mapping ',
  'Author': 'Author',
  'AutomaticallyGeneratePoint': 'Automatically Generate Point',
  'AutomaticLayout': 'Automatic Layout',
  'BaselineAlignment': 'Baseline Alignment ',
  'BasicLegend': 'Basic Legend ',
  'BaseHeight': 'Base Height',
  'BatchBindingDevicePointInformation': 'Batch binding device point information',
  'Bathroom': 'Bathroom ',
  'Beijing': 'Beijing',
  'BeijingKingfisherTechnologyCoLtd': 'Beijing Kingfisher Technology Co. Ltd.',
  'BetweenDevices': 'Between Devices',
  'BindDevicePoints': 'Bind Device Points',
  'BindModels': 'Bind Models ',
  'Blend': 'Blend ',
  'Blur': 'Blur',
  'BlurValue': 'Blur Value',
  'BottomAlignment': 'Bottom Alignment ',
  'Building': 'Building',
  'BuildingHasBeenCreated': 'Building has been created',
  'BuildingInformation': 'Building Information',
  'BuildingList': 'Building List',
  'BuildingName': 'Building name',
  'CacheHit': 'Cache Hit ',
  'CalibrateDrawingCenter': 'Calibrate drawing center',
  'CalibrateDrawingSize': 'Calibrate drawing size',
  'CalibrateFloorCenter': 'Calibrate floor center ',
  'CalibrateFloorSizes': 'Calibrate floor sizes',
  'Cancel': 'Cancel',
  'Cancel2': 'Cancel',
  'CancelClear': 'Cancel Clear ',
  'CancelDeletion': 'Cancel Deletion ',
  'CancelDeletion2': 'Cancel Deletion ',
  'CannotBeEmpty': 'Cannot be empty ',
  'CannotBeRenamed': 'Cannot be renamed ',
  'CannotBeMatched': 'cannot be matched',
  'Category': 'Category',
  'Category01': 'Category 01',
  'Category02': 'Category 02',
  'Category03': 'Category 03',
  'Category04': 'Category 04',
  'Category05': 'Category 05',
  'Category06': 'Category 06',
  'Category07': 'Category 07',
  'CapitalLetters': 'Capital Letters',
  'CenterHorizontally': 'Center Horizontally',
  'CenterPointOffset': 'Center Point Offset',
  'CentralAngle': 'Central Angle ',
  'Centred': 'Centred',
  'Chair': 'Chair',
  'Chamfer': 'Chamfer',
  'ChromaticAberration': 'Chromatic Aberration',
  'CircularGradient': 'Circular Gradient',
  'City': 'City',
  'Clear': 'Clear',
  'ClearAll': 'Clear All?',
  'ClearCanvas': 'Clear Canvas ',
  'ClearScene': 'Clear Scene ',
  'ClickOkToConfirm': 'Click OK to confirm ',
  'ClickOkToConfirm2': 'Click OK to confirm ',
  'ClickToSelect': 'Click to Select',
  'ClickToUpload': 'Click to Upload',
  'Clone': 'Clone  ',
  'CloneProperties': 'Clone Properties',
  'CloningFailed': 'Cloning failed.',
  'CloningSuccessful': 'Cloning successful ',
  'ColourBalance': 'Colour Balance',
  'Colours': 'Colours',
  'ColumnName': 'Column Name ',
  'Comma': 'Comma',
  'Component': 'Component',
  'ComponentCategory': 'Component Category ',
  'ComponentLibrary': 'Component Library',
  'ComponentMarket': 'Component market',
  'ComponentName': 'Component Name',
  'ConferenceRoom': 'Conference room',
  'Confirm': 'Confirm',
  'ConfirmClear': 'Confirm Clear',
  'ConfirmDeletetion': 'Confirm Deletetion',
  'ConfirmThatTheNewPasswordDoesNotMatchTheNewPassword':  'Confirm that the new password does not match the new password',
  'ConfirmThatTheNewPasswordIsNotEmpty': 'Confirm that the new password is not empty',
  'ContactUs': 'Contact Us',
  'Coordinates': 'Coordinates',
  'Copy': 'Copy',
  'Copy2': 'Copy',
  'CopyCode': 'Copy Code',
  'CopySuccessful': 'Copy Successful',
  'NoObjectsCoped':'No Objects Coped',
  'Corridor': 'Corridor',
  'Create': 'Create',
  'CreateAGroup': 'Create a group',
  'CreateANewSession': 'Create a newSession',
  'CreateAProject': 'Create a project',
  'CreateClassification': 'Create classification',
  'CreateCopy': 'Create copy',
  'CreateItem': 'Create project',
  'CreateOrder': 'Create Order',
  'Created': 'Created',
  'CreateBuilding': 'Create building',
  'CreateData': 'Create Data',
  'CreatedSuccessful': 'Created successful',
  'CreateNewPreset': 'Create new preset',
  'CreatePipeline': 'Create Pipeline ',
  'CreateProject': 'Create Project ',
  'CreateRegion': 'Create Region ',
  'ContinuousCreation': 'Continuous Creation',
  'DrawPipeline': 'Pipeline',
  'DrawRoute': 'Route',
  'CreateScene': 'Create Scene',
  'CreateWall': 'Create Wall',
  'CreateWallFill': 'Create Fill Wall',
  'CreateWallNormal': 'Create Normal Wall',
  'CropContents': 'Crop Contents',
  'Crossaxis': 'Cross-axis',
  'CurrentVersion': 'Current Version',
  'Data': 'Data',
  'Data1': 'Data 1',
  'DataBinding': 'Data binding',
  'DataConnectionFailed': 'Data connection failed',
  'DataLabel': 'Data Label',
  'DataNotAvailable': 'Data not available',
  'DataReceived': 'Data Received',
  'DataStatus': 'Data Status',
  'Default': 'Default',
  'Delete': 'Delete',
  'Delete2': 'Delete?',
  'DeleteMapping': 'Delete Mapping',
  'DeleteItAndFindItInTheWastebin': 'Delete it and find it in the wastebin.',
  'DepthOfField': 'Depth of Field ',
  'Description': 'Description',
  'Desk': 'Desk ',
  'Device': 'Device',
  'DeviceCategory': 'Device Category ',
  'DeviceEncoding': 'Device Encoding',
  'DiagramEncoding': 'Diagram Encoding',
  'DiagramName': 'Diagram Name',
  'Diagrams': 'Diagrams',
  'DiagramsNotSupported': 'Diagrams not supported ',
  'Diameter': 'Diameter',
  'Dimensions': 'Dimensions',
  'Direction': 'Direction ',
  'DirectionalLight': 'Directional Light ',
  'DisplayContents': 'Display Contents',
  'Display': 'Display',
  'DistributeHorizontalSpacingEvenly': 'Distribute horizontal spacing evenly ',
  'Divider': 'Divider',
  'Door': 'Door',
  'Dotted': 'Dotted  ',
  'DottedLine': 'Dotted Line',
  'DoubleLine': 'Double Line ',
  'Down': 'Down',
  'DownloadSuccessful': 'Download Successful ',
  'Drag': 'Drag',
  'Drag&Copy': 'Drag & Copy',
  'DragScene': 'Drag scene',
  'East': 'East',
  'Edit': 'Edit',
  'EditData': 'Edit Data',
  'EditIcon': 'Edit Icon',
  'EditTable': 'Edit Table',
  'ElectricityCategory': 'Electricity Category ',
  'ElevatorLobby': 'Elevator Lobby',
  'EllipticalGradient': 'Elliptical Gradient',
  'EmptyWastebin': 'Empty Wastebin',
  'Encoding': 'Encoding',
  'Endpoint': 'Endpoint   ',
  'EndpointCoordinates': 'Endpoint Coordinates',
  'EndRadius': 'End radius',
  'EnterAccountName': 'Enter account name',
  'EnterAccountPassword': 'Enter account password',
  'EnterANewPassword': 'Enter a new password',
  'EnterTheItemCodePlease': 'Enter the item code please',
  'EnterTheOriginalPassword':  'Enter the original password',
  'EnergyConsumptionCategory': 'Energy Consumption Category ',
  'Environment': 'Environment',
  'Equidistant': 'Equidistant ',
  'EquilibriumOfSpaces': 'Equilibrium of Spaces',
  'ExportAs': 'Export As',
  'Export': 'Export',
  'ExitLogin': 'Exit Login',
  'Failed': 'Failed',
  'FailedToCopy': 'Failed to copy',
  'FailedToPublishVersion': 'Failed to publish version ',
  'FailedToSendRequest': 'Failed to send request ',
  'FanCategory': 'Fan Category ',
  'FastAntialiasing': 'Fast Antialiasing ',
  'File': 'File ',
  'Fill': 'Fill',
  'Fill2': 'Fill',
  'FillFieldDetailsHere': 'Fill field details here',
  'Filter': 'Filter',
  'FireLane': 'Fire Lane ',
  'FitContent': 'Fit Content ',
  'Fixed': 'Fixed',
  'FixedHeight': 'Fixed Height',
  'FixedScreen': 'Fixed Screen',
  'FixedWidth': 'Fixed Width',
  'Floor': 'Floor',
  'Floor2': 'Floor',
  'FloorHeight': 'Floor Height ',
  'FloorNotVisible': 'Floor not visible ',
  'FloorSelection': 'Floor Selection',
  'Foundation': 'Foundation',
  'FullScrollbar': 'Full Scrollbar',
  'FunctionalArea': 'Functional area',
  'FunctionTypeError': 'Function type error',
  'Gas': 'Gas',
  'Glass': 'Glass',
  'Glow': 'Glow ',
  'Gradient': 'Gradient',
  'GradientWalls': 'Gradient Walls',
  'GroundGrid': 'Ground Grid',
  'Grouping': 'Grouping ',
  'GroupName': 'Group Name',
  'Height': 'Height',
  'HelpDocument': 'Help document',
  'Help': 'Help',
  'HemisphericCameraSurveillance': 'Hemispheric Camera Surveillance',
  'Hide': 'Hide',
  'Hint': 'Hint',
  'Horizontal': 'Horizontal',
  'Horizontal2': 'Horizontal',
  'HorizontalScrollBar': 'Horizontal Scroll Bar ',
  'Icon': 'Icon',
  'IgnoreMapping': 'Ignore Mapping',
  'Image': 'Image',
  'ImportPosition': 'Import Position ',
  'IncorrectUsernameOrPassword': 'Incorrect username or password',
  'IndiviualElements': 'Indiviual Elements',
  'IndivualIcons': 'Indivual Icons ',
  'InternalSpacing': 'Internal Spacing',
  'InvalidInput': 'Invalid input',
  'InverseColor': 'Inverse Color ',
  'IsPickable': 'Is Pickable',
  'ItMayBeBecauseSomeoneElseHasLoggedIntoYourAccount': 'It may be because someone else has logged into your account. ',
  'Justify': 'Justify ',
  'KeepFixedWhenScrolling': 'Keep fixed when scrolling',
  'KingfisherCloud2dConfigurationTool': 'Kingfisher Cloud 2D Configuration Tool ',
  'KingfisherCloud3dConfigurationTool': 'Kingfisher Cloud 3D Configuration Tool ',
  'KingfisherCloud3dDesigner': 'Kingfisher Cloud 3D Designer ',
  'KingfisherCloudComponentMarket': 'Kingfisher Cloud Component Market',
  'KingfisherClouds': 'Kingfisher Clouds',
  'KingfisherCloudSpaceGovernanceTool': 'Kingfisher Cloud Space Governance Tool',
  'Label': 'Label',
  'LanguageSwitching':'Language Switching',
  'LastUpdateTime': 'Last Update Time',
  'LayerInformation': 'Layer Information',
  'Left': 'Left',
  'LeftAlign': 'Left Align',
  'LeftAndRight': 'Left and right',
  'LeftMouseButton': 'Left mouse button',
  'LeisureArea': 'Leisure area',
  'Length': 'Length',
  'LengthGreaterThan': 'Length greater than',
  'Library': 'Library',
  'Lights': 'Lights',
  'LimitAndResize': 'Limit and resize',
  'LinearGradient': 'Linear Gradient',
  'LineData': 'Line Data',
  'Liquid': 'Liquid',
  'List': 'List',
  'Loadbearing': 'Load-Bearing ',
  'LoadingDiagrams': 'Loading diagrams...',
  'Lobby': 'Lobby',
  'Location': 'Location',
  'Lock': 'Lock',
  'LoggedOutOfTheSystem': 'Logged out of the system ',
  'Login': 'Login',
  'LoginCredentialsExpired': 'Login Credentials Expired',
  'LoginCredentialsNotFound': 'Login credentials not found ',
  'LoginTimedOut': 'Login timed out',
  'Logout': 'Logout ',
  'LowercaseLettersAndSpecialCharacters': 'Lowercase letters and special characters',
  'MainAxis': 'Main axis',
  'MaintainFixed': 'Maintain Fixed ',
  'Manual': 'Manual',
  'MapablePoint': 'Mapable Point',
  'Mapped': 'Mapped',
  'MappingIsDisabled': 'Mapping is Disabled ',
  'Maximum': 'Maximum ',
  'MergeWalls': 'Merge Walls ',
  'Minimum': 'Minimum',
  'MirroredObject': 'Mirrored Object',
  'MirrorLeft&Right': 'Mirror left & right ',
  'MirrorUpAndDown': 'Mirror up and down',
  'Mode': 'Mode',
  'Model': 'Model',
  'Models': 'Models',
  'ModelDragging': 'Model dragging ',
  'ModelWithoutADeviceCode': 'Model without a device code ',
  'ModifiedSuccessfully': 'Modified successfully.',
  'Monochrome': 'Monochrome',
  'More': 'More',
  'Move': 'Move',
  'MoveGroup': 'Move Group',
  'Mulitply': 'Mulitply ',
  'MyFiles': 'My files',
  'Name': 'Name',
  'NameIsADuplicate': 'Name is a duplicate ',
  'NameLength20Characters': 'Name Length 20 Characters',
  'NameLength80Characters': 'Name Length 80 Characters',
  'NetworkError': 'Network Error',
  'New': 'New',
  'NewColumn': 'New Column',
  'NewData': 'New data',
  'NewPasswordFormatError': 'New password format error',
  'NewPasswordToIncludeNumbers': 'New password to include numbers',
  'NoChangeInData': 'No change in data',
  'NoChangeInData2': 'No change in data',
  'NoDataChangesNeedToBeSaved': 'No data changes need to be saved',
  'Noise': 'Noise',
  'NonarrayTypeParameter': 'Non-Array Type Parameter',
  'None': 'None',
  'Normal': 'Normal',
  'NotSelected': 'Not selected',
  'NumberOfFloors': 'Number of floors',
  'ObtainData': 'Obtain Data',
  'Of': 'Of',
  'Office': 'Office',
  'Official': 'Official',
  'OfficialComponent': 'Official Component',
  'OfficialContent': 'Official Content',
  'OnlyFloor': 'Only Floor ',
  'Open': 'Open',
  'Operation': 'Operation',
  'OperationSuccessful': 'Operation Successful',
  'OrDidYouLogIntoTheSystemInAnotherBrowserWindows': 'Or did you log into the system in another browser windows?',
  'OuterSpacing': 'Outer Spacing',
  'Overflow': 'Overflow ',
  'ParametersAre': 'Parameters are... ',
  'Parking': 'Parking ',
  'PasswordCannotBeEmpty': 'Password cannot be empty.',
  'Paste': 'Paste',
  'Path': 'Path',
  'Perspective': 'Perspective',
  'Preview': 'Preview',
  'PickUp': 'Pick Up',
  'PointMode': 'Point',
  'LineMode': 'Line',
  'ArcModel':'Arc',
  'AreaModel': 'Area',
  'Pipeline': 'Pipeline  ',
  'PipelineCategory': 'Pipeline Category ',
  'Placeholder': 'Placeholder ',
  'PleaseCheckNetworkConnection': 'Please check network connection',
  'PleaseEnterAnAccountNumber': 'Please enter an account number',
  'PleaseEnterRequiredItem': 'Please enter required item',
  'PleaseEnterTheAuthor': 'Please enter the author',
  'PleaseEnterThePassword': 'Please enter the password.',
  'PleaseEnterTheProjectCode': 'Please enter the project code',
  'PleaseEnterTheProjectName': 'Please enter the project name ',
  'PleaseFillInTheProjectName': 'Please fill in the project name.',
  'PleaseRelogIn': 'Please re-log in.',
  'PleaseSelect': 'Please select ',
  'PleaseSelectDataEntry': 'Please select data entry',
  'PointsThatWereBoundBeforeTheBindingWasPerformed': 'Points that were bound before the binding was performed',
  'PointType': 'Point Type ',
  'Position': 'Position ',
  'Preset': 'Preset',
  'PresetName': 'Preset Name',
  'Project': 'Project',
  'ProjectCode': 'Project Code',
  'ProjectDescription': 'Project Description',
  'ProjectList': 'Project List',
  'ProjectManagement': 'Project Management',
  'ProjectName': 'Project Name',
  'ProjectSettings': 'Project Settings',
  'Properties': 'Properties',
  'PropertySettings': 'Property Settings',
  'Proportional': 'Proportional',
  'PublishVersion': 'Publish Version ',
  'Quantity': 'Quantity ',
  'RowQuantity': 'RowQuantity',
  'ColumnQuantity': 'ColumnQuantity',
  'Radius': 'Radius',
  'RadicalGradient': 'Radical Gradient ',
  'RectangularWalls': 'Rectangular Walls',
  'RecentlyDeleted': 'Recently Deleted',
  'RecentlyEdited': 'Recently Edited',
  'RecentlyUsed': 'Recently Used',
  'ReconfirmTheNewPassword': 'Reconfirm the new password',
  'Region': 'Region',
  'Regional': 'Regional',
  'RegionSettings': 'Region Settings ',
  'ReplaceModel': 'Replace Model',
  'Request': 'Request',
  'Rename': 'Rename',
  'RequestFailed': 'Request Failed.',
  'RequestFailed2': 'Request Failed',
  'RequestsForServices': 'Requests for services',
  'RequestSuccessful': 'Request successful.',
  'Resources': 'Resources',
  'ResponseSize': 'Response Size',
  'RestoreVersion': 'Restore Version',
  'RestoreVersion2': 'Restore Version',
  'Result': 'Result ',
  'ReturnProjectList': 'Return Project List',
  'Right': 'Right',
  'RightAllignment': 'Right Allignment ',
  'RightMouseButton': 'Right mouse button',
  'Room': 'Room',
  'Rotate': 'Rotate',
  'RotateScene': 'Rotate Scene ',
  'RoundCorner': 'Round Corner',
  'RoundedCornerAdvancedSettings': 'Rounded Corner Advanced Settings',
  'RoundHead': 'Round Head',
  'RouteConfiguration': 'Route Configuration',
  'RunScene': 'Run scene',
  'Save': 'Save',
  'SaveAs': 'Save As',
  'SaveAsName': 'Save as Name',
  'SaveFailed': 'Save Failed',
  'SaveSceneAs': 'Save Scene As',
  'SaveSuccessfiul': 'Save Successfiul',
  'SavingPleaseWait': 'Saving, please wait',
  'ScatterPoints': 'Scatter Points',
  'Scene': 'Scene',
  'SceneName': 'Scene Name',
  'SceneNotSaved': 'Scene not saved',
  'SceneNotSavedConfirmSwitchTo': 'Scene not saved, confirm switch to ',
  'SceneSettings': 'Scene Settings',
  'ScreenSpaceReflection': 'Screen Space Reflection',
  'ScrollWheel': 'Scroll Wheel ',
  'Search': 'Search',
  'SearchModelName': 'Search model name',
  'SelectDate&Time': 'Select Date & Time',
  'SelectFile': 'Select File',
  'SelectIcon': 'Select Icon',
  'SelectPostprocessingEffect': 'Select post-processing effect',
  'Sensors': 'Sensors',
  'Settings': 'Settings',
  'Shadow': 'Shadow',
  'Sharpen': 'Sharpen',
  'Shear': 'Shear',
  'ShortcutKeys': 'Shortcut Keys',
  'Show': 'Show',
  'ShowAllGroups': 'Show all groups',
  'ShowArea': 'Show Area',
  'ShowEncoding': 'Show Encoding',
  'ShowName': 'Show name',
  'Single': 'Single ',
  'SmartOrganize': 'Smart Organize',
  'SolidLine': 'Solid Line ',
  'Southcentral': 'South-central',
  'Space': 'Space',
  'SpaceSurplus': 'Space Surplus',
  'Spacing': 'Spacing',
  'SpatialGovernanceTool': 'Spatial Governance Tool ',
  'SplitWalls': 'Split Walls',
  'SqlIs': 'SQL is ',
  'SsoLoginClosed': 'SSO login closed',
  'SsoUserNotFound': 'SSO user not found',
  'SsoUserOrPasswordInputError': 'SSO user or password input error',
  'Stack': 'Stack',
  'Stairwell': 'Stairwell ',
  'StartingCoordinates': 'Starting coordinates',
  'StartingRadius': 'Starting radius',
  'StartProcessingData': 'Start processing data',
  'StartQuickConstructionUsingInitialTemplateComponents': 'Start Quick Construction using initial template components ',
  'StartRequestingData': 'Start Requesting Data',
  'Status': 'Status',
  'Straighten': 'Straighten ',
  'Strength': 'Strength',
  'StretchLeft&Right': 'Stretch Left & Right ',
  'StretchUpAndDown': 'Stretch up and down',
  'Style1': 'Style 1',
  'Style10': 'Style 10',
  'Style11': 'Style 11',
  'Style12': 'Style 12',
  'Style13': 'Style 13',
  'Style14': 'Style 14',
  'Style15': 'Style 15',
  'Style16': 'Style 16',
  'Style2': 'Style 2',
  'Style3': 'Style 3',
  'Style4': 'Style 4',
  'Style5': 'Style 5',
  'Style6': 'Style 6',
  'Style7': 'Style 7',
  'Style8': 'Style 8',
  'Style9': 'Style 9',
  'Styles': 'Styles',
  'Success': 'Success',
  'SuccessfullyBinded': 'Successfully Binded',
  'SucessfullyPublishedVersion': 'Sucessfully published version ',
  'Surveillance': 'Surveillance',
  'Furniture': 'Furniture',
  'SystemError': 'System Error ',
  'SystemErrorPleaseTryAgainLater': 'System Error, please try again later',
  'Table': 'Table',
  'Tables': 'Tables',
  'TextLabel': 'Text Label',
  'TextPosition': 'Text Position',
  'Texture1': 'Texture 1',
  'TheCiphertextHasBeenCorruptedAndCannotBeResolved': 'The ciphertext has been corrupted and cannot be resolved.',
  'TheDataFormatMatchesSuccessfully': 'The data format matches successfully,',
  'TheDimensionIs': 'The dimension is',
  'TheIndicatorsAre': 'The indicators are ',
  'ThemeSelect': 'Theme select',
  'TheNewPasswordCannotBeEmpty': 'The new password cannot be empty',
  'TheOldPasswordCantBeEmpty': 'The old password can\'t be empty',
  'Thickness': 'Thickness',
  'ThumbnailInformation': 'Thumbnail Information',
  'Timeout': 'Timeout',
  'Tutorial': 'Tutorial',
  'To': 'To',
  'Tools': 'Tools',
  'Tooltip': 'Tooltip',
  'Top': 'Top',
  'Transparency': 'Transparency',
  'Type': 'Type',
  'Types': 'Types',
  'UmappablePoints': 'Umappable Points',
  'UnboundObject': 'Unbound Object',
  'Unclassified': 'Unclassified ',
  'Undefined': 'Undefined',
  'UndoDeletion': 'Undo Deletion  ',
  'Unmapped': 'Unmapped',
  'UnSave': 'UnSave',
  'Up': 'Up',
  'UpAndDown': 'Up and down',
  'Update': 'Update',
  'UpdateCad': 'Update CAD',
  'UpdateDrawings': 'Update Drawings',
  'UpdateFailed': 'Update Failed',
  'UpdateScene': 'Update scene',
  'UpdateTime': 'Update Time',
  'Upload': 'Upload',
  'UploadedSuccessfully': 'Uploaded successfully',
  'UploadedSuccessfullyEffectiveAfterRefresh': 'Uploaded successfully, effective after refresh ',
  'UploadFailed': 'Upload Failed',
  'UploadToServer': 'Upload to Server',
  'User': 'User',
  'UserAccountExpired': 'User account expired',
  'UserComponent': 'User Component',
  'UserContents': 'User Contents',
  'UserDoesNotExist': 'User does not exist',
  'UserManual': 'User Manual ',
  'Username': 'Username',
  'UserNameCannotBeEmpty': 'User name cannot be empty.',
  'UsernameOrPasswordCannotBeEmpty': 'Username or password cannot be empty',
  'UsernameOrPasswordIncorrect': 'Username or password Incorrect',
  'UserNotFound': 'User not found',
  'UserNotRegistered': 'User not registered',
  'UserPrivillegeMissingPleaseContactAdministrator': 'User privillege missing, please contact administrator',
  'Value': 'Value',
  'Value1': 'Value 1',
  'Value10': 'Value',
  'Value2': 'Value 2',
  'Value3': 'Value 3',
  'VerificationFailed': 'Verification Failed ',
  'Version': 'Version',
  'VertexCoordinates': 'Vertex Coordinates',
  'Vertical': 'Vertical',
  'Vertical2': 'Vertical',
  'VerticalCenter': 'Vertical Center ',
  'VerticalScrollBar': 'Vertical Scroll Bar ',
  'VerticalSpaceEquilibrium': 'Vertical Space Equilibrium',
  'Vignette': 'Vignette',
  'Visible': 'Visible',
  'Wall': 'Wall',
  'Wall2': 'Wall',
  'WallLength': 'Wall Length',
  'WallSegment': 'Wall segment',
  'Water': 'Water',
  'WebPage': 'Web page',
  'WechatLogin': 'WeChat Login    ',
  'WechatLoginFailUnknownService': 'WeChat login fail, unknown service',
  'WellKeepYouFor30Days1': 'We\'ll keep you for 30 days.',
  'Wastebin': 'Wastebin',
  'WastebinEmptiedSuccessfully': 'Wastebin emptied successfully',
  'Width': 'Width',
  'Window': 'Window',
  'Wire': 'Wire',
  'XAxis': 'X Axis',
  'XAxisGridlines': 'X Axis Gridlines ',
  'YAxis': 'Y Axis',
  'YAxisGridlines': 'Y Axis Gridlines ',
  'YouHaveAlreadyLoggedOutOfTheSystem': 'You have already logged out of the system,',
  'YouHaveNotObtainedAProductAuthorization': 'You have not obtained a product authorization',
  'YouHaventOperatedForTooLong': 'You haven\'t operated for too long.',
  'YourAccountHasBeenEnteredElsewhereAndYouAreForcedOffline': 'Your account has been entered elsewhere, and you are forced offline.',
  'YourAccountHasBeenEnteredInAnotherDeviceAndYouAreForced': 'Your account has been entered in another device and you are forced offline.',
  'Zoom': 'Zoom',
  'ZoomScene': 'Zoom Scene ',
  'Download': 'Download',
  'PleaseSelectTheProjectAndSceneFirst':'Please Select The Project And Scene First',
  'Permissions':'Permissions',
  '3dComponent': '3d Component',
  'InitialCamera': 'Initial Camera',
  'TriggerEvent': 'Trigger Event',
  'MultiscreenSynchronization': 'Multi screen Synchronization',
  'ScreenGroup': 'Screen Group',
  'ScreenRoles': 'Screen Roles',
  'BackgroundColor': 'Background Color',
  'BorderLineColor': 'Border Line Color',
  'FullScreenControl': 'Full Screen Control',
  'PostEffect': 'Post Effect',
  'CancelCapture':'Cancel Capture',
  'Elevation':'Elevation',
  'ExportSpaceData':'Export Space Data',
  'CreateSpacePointTable':'Create Space PointTable',
  'Theme': 'Theme',
  '3DWindow':'3D Window',
  'sideView':'side View',
  'frontView':'front View',
  'topView':'top View',
  'morning':'morning',
  'NoEditingAtPresent': 'No editing at present',
  'noon':'noon',
  'dusk':'dusk',
  'night':'night',
  '2DWindow':'2D Window',
  'enableAdsorbent':'enable Adsorbent',
  'Grid':'Grid',
  'Object':'Object',
  'ObjectMode':'Object',
  'Partition': 'Partition',
  'Path2': 'Path',
  'Statistics':'Statistics',
  'Picture':'Picture',
  'Positioning':'Positioning',
  'Altitude':'Altitude',
  'UserName':'User Name',
  'Role':'Role',
  'PeriodOfValidity':'PeriodOf Validity',
  'AddUser':'Add User',
  'UserPrivillege':'User Privillege',
  'ForeverValid':'Forever Valid',
  'AreYouSureToDeleteTheUserPermission':'Are You Sure To Delete The User Permission',
  'Sure':'Sure',
  'ProjectAdministrator':'Project Administrator',
  'Editor':'Editor',
  'Viewers':'Viewers',
  'Creator':'Creator',
  'AddedSuccessfully':'Added Successfully',
  'Restore': 'Restore',
  'AfterConfirmation': 'After Confirmation',
  'TheContentWillBePermanentlyDeleted': 'The Content Will Be Permanently Deleted',
  'DeletePermanently': 'Delete Permanently',
  'DetermineWhetherToRestore': 'Determine Whether To Restore',
  'TheContentWillBeRestoredToItsOriginalPosition': 'The Content Will Be Restored To Its Original Position',
  'SetSpace': 'Set Space',
  'AreYouSureYouWantToDeleteItDeleteItAndFindItInTheWastebin': 'Are You Sure You Want To Delete It Delete It And Find It In TheWastebin',
  'ThirtyDays': 'Thirty Days',
  'FileDeleteSuccessful': 'File Delete Successful',
  'FileRestorationSuccess': 'File Restoration Success',
  'WastebinResourceListNetworkRequestError': 'Wastebin Resource List Network Request Error',
  'EmptyWastebinAttemptFailed': 'Empty Wastebin At tempt Failed',
  'CompanyName2': 'CompanyName',
  'PhoneNumber2': 'PhoneNumber',
  'Website': 'Website',
  'ProductName': 'Product Name',
  'VersionNumber': 'Version Number',
  'EditIn': 'Edit In',
  'PriorTo': 'Prior To',
  'DaysAgo': 'Days Ago',
  'AnHourAgo': 'An Hour Ago',
  '#MinutesAgo': 'Minutes Ago',
  'TenSecondsAgo': 'TenSeconds Ago',
  'ExportModel':'Export Model',
  'InformationAnomaly': 'Information Anomaly',
  'PleaseReenterAndTryAgain': 'Please Reenter And Try Again',
  'ImportComponent': 'Import Component',
  'YouCanOnlyUploadIt': 'You Can Only Upload It',
  'Documentation': 'Documentation',
  'Batch':'Batch',
  'SelectAll': 'Select All',
  'Selected1':'Selected',
  'NotSelected1':'Not Selected',
  'DeleteSuccess': 'Delete Success',
  'ConfirmTheDeletion': 'Confirm The Deletion',
  'GoBackToTheHomepage': 'GoBack To The Homepage',
  'NameMayNotBeLongerThan50Bytes': 'Name May Not Be Longer Than 50 Bytes',
  'KeepRatio': 'Keep Aspect Ratio',
  'PleaseEnter': 'Please Enter',
  'ItsBeenModifiedPleaseReland': 'Its Been Modified Please Reland',
  'UserSRemainingSpaceIsNotEnough': 'UserS Remaining Space Is Not Enough',
  'AreYouSureYouWantToSwitchLanguagesAndReload': 'Are You Sure You Want To Switch Languages And Reload？',
  'ImportDirectly': 'Import Directly',
  'BestPerformance': 'Best Performance',
  'Balance': 'Balance',
  'OptimalPerformance': 'Optimal Performance',
  'PleaseFillInTheSceneName': 'Please Fill In The Scene Name',
  'PleaseSelectTheItemFirst': 'Please Select The Item First',
  'EmptyScene': 'Empty Scene',
  'SelectTheGroupOnTheLeft': 'Select The Group On The Left',
  'ImportError':'Import Error',
  'ImportSuccess':'Import Success',
  'NewFloor': 'New Floor',
  'Today': 'Today',
  'CouldNotUpload': 'Could Not Upload',
  'AddSuccess':'Add Success',
  'NameRepeat': 'Name Repeat',
  'CreateSuccessful': 'Create Successful',
  'LimitedNumberOfProjects': 'Limited Number Of Projects',
  'PleaseContactTheAdministrator': 'Please Contact The Administrator',
  'UploadedByMe': 'Uploaded By Me',
  'IAdded':'I Added',
  'Deleted': 'Deleted',
  'DeletionFailed': 'Deletion Failed',
  'TheGroupAlreadyExists': 'The Group Already Exists',
  'PleaseReenter': 'Please Reenter',
  'UpdateFailPleaseContactAdministrator': 'Update Fail Please Contact Administrator',
  'Cannot': 'Can not',
  'Beginning': 'Beginning',
  'MoveSuccessfully': 'Move Successfully',
  'MoveSuccessfullyToViewUnderThisItem': 'Move Successfully To View Under This Item',
  'CanBeMovedTo': 'Can Be Moved To',
  'CheckDown': 'Check Down',
  'Release': 'Release',
  'Auto': 'Auto',
  'WidthFill': 'Width Fill',
  'HeightFill': 'Height Fill',
  'Scale2': 'Scale',
  'Screen': 'Screen',
  'Yes': 'Yes',
  'No': 'No',
  'EditNotSupported': 'Edit Not Supported',
  'Add1': 'Add',
  'ComponentDoesNotSupportSharing': 'Component Does Not Support Sharing',
  'OnBoard': 'On Board',
  'MoveToItem': 'Move To Item',
  'FailPleaseContactAdministrator': 'Fail Please Contact Administrator',
  'MoveToMyFile2': 'Move To MyFile',
  'ViewingInTheProject': 'Viewing In The Project',
  'FailedToDeletePage': 'Failed To Delete Page',
  'GroupAs': 'Group As',
  'ViewDown': 'View Down',
  'MoveFailPleaseContactAdministrator': 'Move Fail Please Contact Administrator',
  'SelectAGroup': 'Select A Group',
  'YouHaveToChooseAProject': 'You Have To Choose A Project',
  'PostLinksCopiedToClipboard': 'Post Links Copied To Clipboard',
  'PublishFailPleaseContactAdministrator': 'Publish Fail Please Contact Administrator',
  '3dScene': '3d Scene',
  'DeterminesWhetherThisReleaseLinkShouldBePlacedNext': 'Determines Whether This Release Link Should Be Placed Next',
  'Discontinue': 'Discontinue',
  'NameCannotBeEmpty2': 'Name Cannot Be Empty',
  'TheLibraryCantBeEmpty': 'The Library Cant Be Empty',
  'PostedSuccessfully': 'Posted Successfully',
  'PleaseMoveToLibrary': 'Please Move To Library',
  'MainBody': 'Main Body',
  'UpdateSuccessful': 'Update Successful',
  'ThisOperationIsNotCurrentlySupportedForThisTheme':'This Operation Is Not Currently Supported For This Theme',
  'DeterminesThatAllContentsAreEmptied': 'Determines That All Contents Are Emptied',
  'Question?': '?',
  'TheContentsWillBePermanentlyRemovedFromTheWastebin': 'The Contents Will Be Permanently Removed From The Wastebin',
  'Clear2': 'Clear',
  'Already': 'Already',
  'JumpAddress': 'Jump Address',
  'Jump': 'Jump',
  'MovedSuccessfullyTo': 'Moved Successfully To',
  'OperateFailPleaseContactAdministrator': 'Operate Fail Please Contact Administrator',
  'Copy5': 'Copy',
  'Close': 'Close',
  'PageDeleteSuccessful': 'Page Delete Successful',
  'PleaseSelectTheCorrectDate':'Please Select The Correct Date',
  'MyFile2':'My File',
  'TheNameMustNotExceed50Words':'The Name Must Not Exceed 50 Words',
  'SaveTheCurrentScene':'Save The Current Scene',
}
export default en;