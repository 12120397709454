const zh_CN = {
  language: {
    "zh-CN": '简体中文',
    "zh-TW": '繁体中文',
    "en": 'English',
  },
  '2dConfigurationTool': '二维组态工具',
  '3d': '三维',
  '3dConfigurationTool': '三维组态工具',
  '3dDesigner': '三维设计器',
  '3dDisplay': '三维显示',
  '?': '吗?',
  'About': '关于',
  'AboutUs': '关于我们',
  'ABuildingWithTheSameNameAlreadyExists': '已存在相同名称建筑',
  'Add': '添加',
  'AddAttribute': '新增属性',
  'AddDrawing': '添加图纸',
  'AddNew': '新增',
  'AddPosition': '新增机位.',
  'AddSelection': '添选',
  'AddStatus': '新增状态',
  'AdvancedOutlineSettings': '描边高级设置',
  'AdvancedSettings': '高级设置',
  'Alignment': '对齐方式',
  'AlignUp': '上对齐',
  'All': '全部',
  'AllWidgets': '全部构件',
  'AllowClickUser': '允许点击用户',
  'AllowMovingToGroup': '允许移动到组',
  'AllowPropertySettings': '允许属性设置',
  'AlongXAxis': 'X轴向',
  'AlongYaxis': 'Y轴向',
  'AlreadyAdded': '已经添加',
  'AmbientOcclusion': '环境光遮蔽',
  'AModelThatWasBoundBeforeTheBindingWasPerformed': '执行绑定前已绑定的模型',
  'Angle': '角度',
  'AnotherUserLoggedInWithTheSameAccount': '同账号他人登录',
  'Appearances': '方头',
  'Area': '面积',
  'AreYouSure': '确定删除?',
  'AreYouSureItsDeleted': '是否确定删除?',
  'AreYouSureYouWantToDeleteThisData': '确定删除此数据吗?',
  'Arrange': '排布',
  'AttemptMapping': '尝试映射',
  'Author': '作者',
  'AutomaticallyGeneratePoint': '自动生成点位',
  'AutomaticLayout': '自动布局',
  'BaselineAlignment': '基线对齐',
  'BasicLegend': '基本图列',
  'BaseHeight': '基高',
  'BatchBindingDevicePointInformation': '批量绑定设备点信息',
  'Bathroom': '卫生间',
  'Beijing': '北京',
  'BeijingKingfisherTechnologyCoLtd': '北京翠鸟智擎科技有限公司',
  'BetweenDevices': '设备间',
  'BindDevicePoints': '绑定设备点位',
  'BindModels': '绑定模型',
  'Blend': '混合',
  'Blur': '模糊',
  'BlurValue': '模糊值',
  'BottomAlignment': '下对齐',
  'Building': '建筑',
  'BuildingHasBeenCreated': '已创建建筑',
  'BuildingInformation': '建筑信息',
  'BuildingList': '建筑列表',
  'BuildingName': '建筑名',
  'CacheHit': '命中缓存',
  'CalibrateDrawingCenter': '校准图纸中心点',
  'CalibrateDrawingSize': '校准图纸尺寸',
  'CalibrateFloorCenter': '校准楼层中心点',
  'CalibrateFloorSizes': '校准楼层尺寸',
  'Cancel': '取消',
  'Cancel2': '取 消',
  'CancelClear': '放弃清空',
  'CancelDeletion': '放弃删除',
  'CancelDeletion2': '取消删除',
  'CannotBeEmpty': '不能为空',
  'CannotBeRenamed': '不能重名',
  'CannotBeMatched': '未匹配',
  'Category': '分类',
  'Category01': '分类01',
  'Category02': '分类02',
  'Category03': '分类03',
  'Category04': '分类04',
  'Category05': '分类05',
  'Category06': '分类06',
  'Category07': '分类07',
  'CapitalLetters': '大写字母',
  'CenterHorizontally': '水平居中',
  'CenterPointOffset': '中心点偏移',
  'CentralAngle': '圆心角',
  'Centred': '居中',
  'Chair': '椅',
  'Chamfer': '倒角',
  'ChromaticAberration': '色散',
  'CircularGradient': '圆形渐变',
  'City': '城市',
  'Clear': '清空',
  'ClearAll': '是否清空所有?',
  'ClearCanvas': '清空画布',
  'ClearScene': '场景清空',
  'ClickOkToConfirm': '确定',
  'ClickOkToConfirm2': '确定',
  'ClickToSelect': '点击选择',
  'ClickToUpload': '点击上传',
  'Clone': '克隆',
  'CloneProperties': '克隆属性',
  'CloningFailed': '克隆失败',
  'CloningSuccessful': '克隆成功',
  'ColourBalance': '色彩均衡',
  'Colours': '颜色',
  'ColumnName': '列名',
  'Comma': '，',
  'Component': '组件',
  'ComponentCategory': '构件类',
  'ComponentLibrary': '组件库',
  'ComponentMarket': '组件市场',
  'ComponentName': '组件名称',
  'ConferenceRoom': '会议室',
  'Confirm': '确认',
  'ConfirmClear': '确认清空',
  'ConfirmDeletetion': '确认删除',
  'ConfirmThatTheNewPasswordDoesNotMatchTheNewPassword': '确认新密码与新密码不一致',
  'ConfirmThatTheNewPasswordIsNotEmpty': '确认新密码不能为空',
  'ContactUs': '联系我们',
  'Coordinates': '坐标',
  'Copy': '副本',
  'Copy2': '复制',
  'CopyCode': '复制代码',
  'CopySuccessful': '复制成功',
  'NoObjectsCoped':'没有复制目标',
  'Corridor': '走廊',
  'Create': '创建',
  'CreateAGroup': '创建一个分组',
  'CreateANewSession': '创建新的会话',
  'CreateAProject': '创建一个项目',
  'CreateClassification': '创建分类',
  'CreateCopy': '创建副本',
  'Created': '创建时间',
  'CreateItem': '创建项目',
  'CreateOrder': '创建顺序',
  'CreateSpacePointTable':'生成空间点表',
  'CreateBuilding': '创建建筑',
  'CreateData': '创建数据',
  'CreatedSuccessful': '创建成功',
  'CreateNewPreset': '创建新的预设',
  'CreatePipeline': '创建管线',
  'CreateProject': '创建项目',
  'CreateRegion': '创建区域',
  'ContinuousCreation': '连续创建',
  'DrawPipeline': '管线',
  'DrawRoute': '路线',
  'CreateScene': '创建场景',
  'CreateWall': '创建墙体',
  'CreateWallFill': '创建填充墙体',
  'CreateWallNormal': '创建普通墙体',
  'CropContents': '裁剪内容',
  'Crossaxis': '交叉轴',
  'CurrentVersion': '当前版本',
  'Data': '数据',
  'Data1': '数据1',
  'DataBinding': '数据绑定',
  'DataConnectionFailed': '数据连接失败',
  'DataLabel': '数据标签',
  'DataNotAvailable': '暂无数据',
  'DataReceived': '收到数据',
  'DataStatus': '数据状态',
  'Default': '默认',
  'Delete': '删除',
  'Delete2': '是否删除',
  'DeleteMapping': '删除映射',
  'DeleteItAndFindItInTheWastebin': '删除后可以在回收站中找到',
  'DepthOfField': '景深',
  'Description': '描述',
  'Desk': '书桌',
  'Device': '设备',
  'DeviceCategory': '设备类',
  'DeviceEncoding': '设备编码',
  'DiagramEncoding': '图纸编码',
  'DiagramName': '图元名称',
  'Diagrams': '图纸',
  'DiagramsNotSupported': '图纸不支持',
  'Diameter': '直径',
  'Dimensions': '尺寸',
  'Direction': '方向',
  'DirectionalLight': '方向光',
  'DisplayContents': '展示内容',
  'Display': '显示',
  'DistributeHorizontalSpacingEvenly': '水平间距均分',
  'Divider': '隔线',
  'Door': '门',
  'Dotted': '点状',
  'DottedLine': '虚线',
  'DoubleLine': '双线',
  'Down': '下',
  'DownloadSuccessful': '下载成功',
  'Drag': '拖拽',
  'Drag&Copy': '拖拽复制',
  'DragScene': '拖动场景',
  'East': '华东',
  'Edit': '编辑',
  'EditData': '编辑数据',
  'EditIcon': '编辑图标',
  'EditTable': '编辑表格',
  'ElectricityCategory': '电力类',
  'ElevatorLobby': '电梯厅',
  'EllipticalGradient': '椭圆渐变',
  'EmptyWastebin': '清空回收站',
  'Encoding': '编码',
  'Endpoint': '端点',
  'EndpointCoordinates': '终点坐标',
  'EndRadius': '末端半径',
  'EnterAccountName': '填入账户名',
  'EnterAccountPassword': '填入账户密码',
  'EnterANewPassword': '输入新密码',
  'EnterTheItemCodePlease': '请输入项目编码',
  'EnterTheOriginalPassword': '输入原密码',
  'EnergyConsumptionCategory': '能耗类',
  'Environment': '环境',
  'Equidistant': '等距',
  'EquilibriumOfSpaces': '间距均分',
  'ExportAs': '导出为',
  'Export': '导出',
  'ExitLogin': '退出登录',
  'Failed': '失败',
  'FailedToCopy': '复制失败',
  'FailedToPublishVersion': '发布版本失败',
  'FailedToSendRequest': '发送请求失败',
  'FanCategory': '风机类',
  'FastAntialiasing': '快速抗锯齿',
  'File': '文件',
  'Fill': '铺满',
  'Fill2': '填充',
  'FillFieldDetailsHere': '此处填写字段详细内容',
  'Filter': '滤镜',
  'FireLane': '消防通道',
  'FitContent': '适应内容',
  'Fixed': '定',
  'FixedHeight': '固定高度',
  'FixedScreen': '固定屏幕',
  'FixedWidth': '固定宽度',
  'Floor': '楼层',
  'Floor2': '地板',
  'FloorHeight': '层高',
  'FloorNotVisible': '楼层不可见',
  'FloorSelection': '楼层选择',
  'Foundation': '基础',
  'FullScrollbar': '完整滚动条',
  'FunctionalArea': '功能区',
  'FunctionTypeError': '函数类型错误',
  'Gas': '气',
  'Glass': '玻璃',
  'Glow': '光晕',
  'Gradient': '渐变',
  'GradientWalls': '梯形墙体',
  'GroundGrid': '地面网格',
  'Grouping': '分组',
  'GroupName': '分组名称',
  'Height': '高度',
  'Help': '帮助',
  'HelpDocument': '帮助文档',
  'HemisphericCameraSurveillance': '半球机监控',
  'Hide': '隐藏',
  'Hint': '提示',
  'Horizontal': '横向',
  'Horizontal2': '水平',
  'HorizontalScrollBar': '左右滚动条',
  'Icon': '图标',
  'IgnoreMapping': '忽略映射',
  'Image': '头像图片',
  'ImportPosition': '导入点位',
  'IncorrectUsernameOrPassword': '用户或密码输入错误',
  'IndiviualElements': '个元素',
  'IndivualIcons': '个图标',
  'InternalSpacing': '内间距',
  'InvalidInput': '无效输入',
  'InverseColor': '反色',
  'IsPickable': '可拾取',
  'ItMayBeBecauseSomeoneElseHasLoggedIntoYourAccount': '可能是因为有其他人登录了您的账户,',
  'Justify': '两端对齐',
  'KeepFixedWhenScrolling': '滚动时保持固定',
  'KingfisherCloud2dConfigurationTool': '翠鸟云二维组态工具',
  'KingfisherCloud3dConfigurationTool': '翠鸟云三维组态工具',
  'KingfisherCloud3dDesigner': '翠鸟云三维设计器',
  'KingfisherCloudComponentMarket': '翠鸟云组件市场',
  'KingfisherClouds': '翠鸟云',
  'KingfisherCloudSpaceGovernanceTool': '翠鸟云空间治理工具',
  'Label': '标签',
  'LanguageSwitching': '语言切换',
  'LastUpdateTime': '最后更新时间',
  'LayerInformation': '图层信息',
  'Left': '左',
  'LeftAlign': '左对齐',
  'LeftAndRight': '左右',
  'LeftMouseButton': '鼠标左键',
  'LeisureArea': '休闲区',
  'Length': '长度',
  'LengthGreaterThan': '长度大于',
  'Library': '库',
  'Lights': '灯',
  'LimitAndResize': '约束和调整大小',
  'LinearGradient': '线性渐变',
  'LineData': '条数据',
  'Liquid': '液体',
  'List': '列表',
  'Loadbearing': '承重',
  'LoadingDiagrams': '图纸加载中...',
  'Lobby': '大厅',
  'Location': '位置',
  'Lock': '锁定',
  'LoggedOutOfTheSystem': '已经登出系统',
  'Login': '登录',
  'LoginCredentialsExpired': '登录凭据失效',
  'LoginCredentialsNotFound': '未找到登录凭据',
  'LoginTimedOut': '登录超时',
  'Logout': '退出登录',
  'LowercaseLettersAndSpecialCharacters': '小写字母和特殊字符',
  'MainAxis': '主轴',
  'MaintainFixed': '保持固定',
  'Manual': '手动',
  'MapablePoint': '可被映射点位',
  'Mapped': '已映射',
  'MappingIsDisabled': '映射已经停用',
  'Maximum': '最大',
  'MergeWalls': '墙体合并',
  'Minimum': '最小',
  'MirroredObject': '镜像物体',
  'MirrorLeft&Right': '左右镜像',
  'MirrorUpAndDown': '上下镜像',
  'Mode': '模式',
  'Model': '模型',
  'Models': '个模型',
  'ModelDragging': '模型拖拽',
  'ModelWithoutADeviceCode': '没有设备码的模型',
  'ModifiedSuccessfully': '修改成功',
  'Monochrome': '单色',
  'More': '更多',
  'Move': '移动',
  'MoveGroup': '移动分组',
  'Mulitply': '正片叠底',
  'MyFiles': '我的文档',
  'Name': '名称',
  'NameIsADuplicate': '名称重复',
  'NameLength20Characters': '名称长度20字符',
  'NameLength80Characters': '名称长度80字符',
  'NetworkError': '网络错误',
  'New': '新建',
  'NewColumn': '新列',
  'NewData': '新数据',
  'NewPasswordFormatError': '新密码格式错误',
  'NewPasswordToIncludeNumbers': '新密码需包含数字',
  'NoChangeInData': '数据未发生变化,',
  'NoChangeInData2': '数据未发生变化',
  'NoDataChangesNeedToBeSaved': '数据未变更，无需保存',
  'Noise': '噪点',
  'NonarrayTypeParameter': '非数组类型参数',
  'None': '无',
  'Normal': '普通',
  'NotSelected': '未选择',
  'NumberOfFloors': '楼层数量',
  'ObtainData': '获取数据',
  'Of': '的',
  'Office': '办公室',
  'Official': '官方',
  'OfficialComponent': '官方组件',
  'OfficialContent': '官方内容',
  'OnlyFloor': '仅楼层',
  'Open': '打开',
  'Operation': '操作',
  'OperationSuccessful': '操作成功',
  'OrDidYouLogIntoTheSystemInAnotherBrowserWindows': '或者是您在其他浏览器窗口登录了系统',
  'OuterSpacing': '外间距',
  'Overflow': '溢出',
  'ParametersAre': '参数是',
  'Parking': '停车',
  'PasswordCannotBeEmpty': '密码不能为空',
  'Paste': '粘贴',
  'Path': '路径',
  'Perspective': '视角',
  'Preview': '预览',
  'PickUp': '拾取',
  'PointMode': '点',
  'LineMode': '线',
  'ArcModel':'弧线',
  'AreaModel': '面',
  'Pipeline': '管线',
  'PipelineCategory': '管线类',
  'Placeholder': '占位',
  'PleaseCheckNetworkConnection': '请检查网络连接',
  'PleaseEnterAnAccountNumber': '请输入账号',
  'PleaseEnterRequiredItem': '请输入必选项',
  'PleaseEnterTheAuthor': '请输入作者',
  'PleaseEnterThePassword': '请输入密码',
  'PleaseEnterTheProjectCode': '请输入项目编码',
  'PleaseEnterTheProjectName': '请输入项目名称',
  'PleaseFillInTheProjectName': '请填写项目名',
  'PleaseRelogIn': '请重新登录',
  'PleaseSelect': '请选择',
  'PleaseSelectDataEntry': '请先选中一条数据',
  'PointsThatWereBoundBeforeTheBindingWasPerformed': '执行绑定前已绑定的点位',
  'PointType': '点位类型',
  'Position': '点位',
  'Preset': '预设',
  'PresetName': '预设名称',
  'Project': '项目',
  'ProjectCode': '项目编码',
  'ProjectDescription': '项目描述',
  'ProjectList': '项目列表',
  'ProjectManagement': '项目管理',
  'ProjectName': '项目名',
  'ProjectSettings': '项目设置',
  'Properties': '属性',
  'PropertySettings': '属性设置',
  'Proportional': '等比',
  'PublishVersion': '发布版本',
  'Quantity': '数量',
  'RowQuantity': '行数量',
  'ColumnQuantity': '列数量',
  'Radius': '半径',
  'RadicalGradient': '径向渐变',
  'RectangularWalls': '矩形墙体',
  'RecentlyDeleted': '最近删除',
  'RecentlyEdited': '最近编辑',
  'RecentlyUsed': '最近使用',
  'ReconfirmTheNewPassword': '再次确认新密码',
  'Region': '地区',
  'Regional': '区域',
  'RegionSettings': '区域设置',
  'ReplaceModel': '替换模型',
  'Request': '请求',
  'Rename': '重命名',
  'RequestFailed': '请求失败,',
  'RequestFailed2': '请求失败',
  'RequestsForServices': '请求服务',
  'RequestSuccessful': '请求成功',
  'Resources': '资源',
  'ResponseSize': '响应大小',
  'RestoreVersion': '回退版本',
  'RestoreVersion2': '回滚版本',
  'Result': '结果',
  'ReturnProjectList': '返回项目',
  'Right': '右',
  'RightAllignment': '右对齐',
  'RightMouseButton': '鼠标右键',
  'Room': '房间',
  'Rotate': '旋转',
  'RotateScene': '旋转场景',
  'RoundCorner': '圆角',
  'RoundedCornerAdvancedSettings': '圆角高级设置',
  'RoundHead': '圆头',
  'RouteConfiguration': '路由配置',
  'RunScene': '运行场景',
  'Save': '保存',
  'SaveAs': '另存为',
  'SaveAsName': '另存为名称',
  'SaveFailed': '保存失败',
  'SaveSceneAs': '场景另存为',
  'SaveSuccessfiul': '另存成功',
  'SavingPleaseWait': '正在保存中，请稍后',
  'ScatterPoints': '撒点',
  'Scene': '场景',
  'SceneName': '场景名称',
  'SceneNotSaved': '场景未保存',
  'SceneNotSavedConfirmSwitchTo': '场景未保存，确定切换为',
  'SceneSettings': '场景设置',
  'ScreenSpaceReflection': '屏幕空间反射',
  'ScrollWheel': '鼠标滚轴',
  'Search': '搜索',
  'SearchModelName': '搜索模型名称',
  'SelectDate&Time': '选择日期时间',
  'SelectFile': '选取文件',
  'SelectIcon': '选择图标',
  'SelectPostprocessingEffect': '选择一种后期效果',
  'Sensors': '传感器',
  'Settings': '设置',
  'Shadow': '阴影',
  'Sharpen': '锐化',
  'Shear': '剪力',
  'ShortcutKeys': '快捷键',
  'Show': '显示',
  'ShowAllGroups': '显示全部分组',
  'ShowArea': '显示面积',
  'ShowEncoding': '显示编码',
  'ShowName': '显示名称',
  'Single': '个',
  'SmartOrganize': '智能整理',
  'SolidLine': '实线',
  'Southcentral': '中南',
  'Space': '场景',
  'SpaceSurplus': '空间剩余',
  'Spacing': '间距',
  'SpatialGovernanceTool': '空间治理工具',
  'SplitWalls': '墙体拆分',
  'SqlIs': 'SQL是',
  'SsoLoginClosed': 'SSO登录已关闭',
  'SsoUserNotFound': 'SSO用户未找到',
  'SsoUserOrPasswordInputError': 'SSO用户或密码输入错误',
  'Stack': '堆叠',
  'Stairwell': '楼梯间',
  'StartingCoordinates': '起点坐标',
  'StartingRadius': '起始半径',
  'StartProcessingData': '开始处理数据',
  'StartQuickConstructionUsingInitialTemplateComponents': '利用初始模板组件开始快速搭建',
  'StartRequestingData': '开始请求数据',
  'Status': '状态',
  'Straighten': '打直',
  'Strength': '强度',
  'StretchLeft&Right': '左右拉伸',
  'StretchUpAndDown': '上下拉伸',
  'Style1': '样式一',
  'Style10': '样式十',
  'Style11': '样式十一',
  'Style12': '样式十二',
  'Style13': '样式十三',
  'Style14': '样式十四',
  'Style15': '样式十五',
  'Style16': '样式十六',
  'Style2': '样式二',
  'Style3': '样式三',
  'Style4': '样式四',
  'Style5': '样式五',
  'Style6': '样式六',
  'Style7': '样式七',
  'Style8': '样式八',
  'Style9': '样式九',
  'Styles': '样式',
  'Success': '成功',
  'SuccessfullyBinded': '成功绑定',
  'SucessfullyPublishedVersion': '发布版本成功',
  'Surveillance': '监控',
  'Furniture': '家具',
  'SystemError': '系统异常',
  'SystemErrorPleaseTryAgainLater': '系统异常，请稍后再试',
  'Table': '桌',
  'Tables': '的表',
  'TextLabel': '文字标签',
  'TextPosition': '文字位置',
  'Texture1': '材质1',
  'TheCiphertextHasBeenCorruptedAndCannotBeResolved': '密文已被破坏，无法解析',
  'TheDataFormatMatchesSuccessfully': '数据格式匹配成功,',
  'TheDimensionIs': '维度是',
  'TheIndicatorsAre': '指标是',
  'ThemeSelect': '主题选择',
  'TheNewPasswordCannotBeEmpty': '新密码不能为空',
  'TheOldPasswordCantBeEmpty': '旧密码不能为空',
  'Thickness': '厚度',
  'ThumbnailInformation': '图元信息',
  'Timeout': '超时',
  'Tutorial': '教程',
  'To': '到',
  'Tools': '工具',
  'Tooltip': '气泡提示',
  'Top': '顶点',
  'Transparency': '透明度',
  'Type': '类型',
  'Types': '种类型',
  'UmappablePoints': '不可被映射点位',
  'UnboundObject': '未绑定对象',
  'Unclassified': '未分类',
  'Undefined': '未定义',
  'UndoDeletion': '反删除',
  'Unmapped': '未映射',
  'UnSave': '不保存',
  'Up': '上',
  'UpAndDown': '上下',
  'Update': '更新',
  'UpdateCad': '更新CAD',
  'UpdateDrawings': '更新图纸',
  'UpdateFailed': '更新失败',
  'UpdateScene': '更新场景',
  'UpdateTime': '更新时间',
  'Upload': '上传',
  'UploadedSuccessfully': '上传成功',
  'UploadedSuccessfullyEffectiveAfterRefresh': '上传成功,刷新后可生效',
  'UploadFailed': '上传失败',
  'UploadToServer': '上传到服务器',
  'User': '用户',
  'UserAccountExpired': '用户账号已过期',
  'UserComponent': '用户组件',
  'UserContents': '用户内容',
  'UserDoesNotExist': '用户不存在',
  'UserManual': '使用文档',
  'Username': '用户名',
  'UserNameCannotBeEmpty': '用户名不能为空',
  'UsernameOrPasswordCannotBeEmpty': '用户名或密码不能为空',
  'UsernameOrPasswordIncorrect': '用户名或者密码错误',
  'UserNotFound': '用户未找到',
  'UserNotRegistered': '用户尚未注册',
  'UserPrivillegeMissingPleaseContactAdministrator': '用户权限不足，请联系管理员提升权限',
  'Value': '值',
  'Value1': '值1',
  'Value10': '数值',
  'Value2': '值2',
  'Value3': '值3',
  'VerificationFailed': '验证失败,',
  'Version': '版本',
  'VertexCoordinates': '顶点坐标',
  'Vertical': '纵向',
  'Vertical2': '垂直',
  'VerticalCenter': '垂直居中',
  'VerticalScrollBar': '上下滚动条',
  'VerticalSpaceEquilibrium': '垂直间距均分',
  'Vignette': '暗角',
  'Visible': '可见',
  'Wall': '墙',
  'Wall2': '墙壁',
  'WallLength': '墙体长度',
  'WallSegment': '墙段',
  'Water': '水',
  'WebPage': '网页',
  'WechatLogin': '微信登录',
  'WechatLoginFailUnknownService': '微信登录失败，未知服务',
  'WellKeepYouFor30Days1': '，我们将为保留30天。',
  'Wastebin': '回收站',
  'WastebinEmptiedSuccessfully': '回收站清空成功',
  'Width': '宽度',
  'Window': '窗',
  'Wire': '电',
  'XAxis': 'X轴',
  'XAxisGridlines': 'X轴隔线',
  'YAxis': 'Y轴',
  'YAxisGridlines': 'Y轴隔线',
  'YouHaveAlreadyLoggedOutOfTheSystem': '您已经登出系统,',
  'YouHaveNotObtainedAProductAuthorization': '您尚未获取产品授权',
  'YouHaventOperatedForTooLong': '您因为太久没有操作,',
  'YourAccountHasBeenEnteredElsewhereAndYouAreForcedOffline': '您的账号已在其他环境登录，您被迫下线',
  'YourAccountHasBeenEnteredInAnotherDeviceAndYouAreForced': '您的账号已在其他设备登录，您被迫下线',
  'Zoom': '缩放',
  'ZoomScene': '缩放场景',
  'Download': '下载',
  'PleaseSelectTheProjectAndSceneFirst':'请先选择项目和场景',
  'Permissions':'权限',
  '3dComponent': '三维组件',
  'InitialCamera': '初始相机',
  'TriggerEvent': '触发事件',
  'MultiscreenSynchronization': '多屏同步',
  'ScreenGroup': '屏幕组',
  'ScreenRoles': '屏幕角色',
  'BackgroundColor': '背景颜色',
  'BorderLineColor': '边框线颜色',
  'FullScreenControl': '全屏控制',
  'PostEffect': '后期',
  'CancelCapture':'取消捕获',
  'Elevation':'高程',
  'ExportSpaceData':'导出空间数据',
  'Theme': '主题',
  '3DWindow':'3D视窗',
  'sideView':'侧视图',
  'frontView':'正视图',
  'topView':'顶视图',
  'morning':'上午',
  'noon':'中午',
  'NoEditingAtPresent': '暂无编辑',
  'dusk':'黄昏',
  'night':'夜晚',
  '2DWindow':'2D视窗',
  'enableAdsorbent':'开启吸附',
  'Grid':'网格',
  'Object':'对象',
  'ObjectMode':'对象',
  'Path2': '路线',
  'Statistics':'统计',
  'Partition': '隔断',
  'Picture':'图片',
  'Positioning':'定位',
  'Altitude':'海拔',
  'UserName':'用户名称',
  'Role':'角色',
  'PeriodOfValidity':'有效期',
  'AddUser':'添加用户',
  'UserPrivillege':'用户权限',
  'ForeverValid':'永久有效',
  'AreYouSureToDeleteTheUserPermission':'确定删除该用户权限吗',
  'Sure':'确定',
  'ProjectAdministrator':'项目管理员',
  'Editor':'编辑者',
  'Viewers':'查看者',
  'Creator':'创建者',
  'AddedSuccessfully':'添加成功',
  'Restore': '恢复',
  'AfterConfirmation': '确定后',
  'TheContentWillBePermanentlyDeleted': '该内容将会被永久删除',
  'DeletePermanently': '永久删除',
  'DetermineWhetherToRestore': '是否确定恢复',
  'TheContentWillBeRestoredToItsOriginalPosition': '该内容将会被恢复到原来位置',
  'SetSpace': '设置空间',
  'AreYouSureYouWantToDeleteItDeleteItAndFindItInTheWastebin': '是否确定删除?删除后可以在回收站中找到，我们将为您保留',
  'ThirtyDays': '30天。',
  'FileDeleteSuccessful': '文件删除成功',
  'FileRestorationSuccess': '文件恢复成功',
  'WastebinResourceListNetworkRequestError': '回收站资源列表网络请求错误',
  'EmptyWastebinAttemptFailed': '回收站清空失败',
  'CompanyName2': '企业名称',
  'PhoneNumber2': '联系电话',
  'Website': '网址',
  'ProductName': '产品名称',
  'VersionNumber': '版本号',
  'EditIn': '编辑于',
  'PriorTo': '年之前',
  'DaysAgo': '天之前',
  'AnHourAgo': '小时之前',
  '#MinutesAgo': '分钟之前',
  'TenSecondsAgo': '10秒之前',
  'ExportModel':'导出模型',
  'InformationAnomaly': '信息异常',
  'PleaseReenterAndTryAgain': '请重新登录再尝试',
  'ImportComponent': '导入组件',
  'YouCanOnlyUploadIt': '只能上传',
  'Documentation': '文件',
  'Batch':'批量',
  'SelectAll': '全选',
  'Selected1':'已选择',
  'NotSelected1':'未选择',
  'DeleteSuccess': '删除成功',
  'ConfirmTheDeletion': '确认删除吗',
  'GoBackToTheHomepage': '返回主页',
  'NameMayNotBeLongerThan50Bytes': '名称长度不能超过50个字节',
  'KeepRatio': '保持比例',
  'PleaseEnter': '请输入',
  'ItsBeenModifiedPleaseReland': '修改成功，请重新登陆',
  'UserSRemainingSpaceIsNotEnough': '用户剩余可用空间不足',
  'AreYouSureYouWantToSwitchLanguagesAndReload': '确定要切换语言并重新加载吗？',
  'ImportDirectly': '直接导入',
  'BestPerformance': '性能最优',
  'Balance': '平衡',
  'OptimalPerformance': '效果最优',
  'PleaseFillInTheSceneName': '请填写场景名称',
  'PleaseSelectTheItemFirst': '请先选择项目',
  'EmptyScene': '空场景',
  'SelectTheGroupOnTheLeft': '请选择左侧的分组',
  'ImportError':'导入失败',
  'ImportSuccess':'导入成功',
  'NewFloor': '新建楼层',
  'Today': '今天',
  'CouldNotUpload': '无法上传',
  'AddSuccess':'添加成功',
  'NameRepeat': '名称重复',
  'CreateSuccessful': '创建成功',
  'LimitedNumberOfProjects': '项目数量受限',
  'PleaseContactTheAdministrator': '请联系管理员',
  'UploadedByMe': '我上传的',
  'IAdded':'我添加的',
  'Deleted': '已删除',
  'DeletionFailed': '删除失败',
  'TheGroupAlreadyExists': '该分组已存在',
  'PleaseReenter': '请重新输入',
  'UpdateFailPleaseContactAdministrator': '更新失败，请联系管理员',
  'Cannot': '不可以',
  'Beginning': '开头',
  'MoveSuccessfully': '移动成功',
  'MoveSuccessfullyToViewUnderThisItem': '移动成功，可至该项目下进行查看',
  'CanBeMovedTo': '可移至',
  'CheckDown': '下进行查看',
  'Release': '发布',
  'Auto': '自动',
  'WidthFill': '宽度填充',
  'HeightFill': '高度填充',
  'Scale2': '比例缩放',
  'Screen': '屏风',
  'Yes': '是',
  'No': '否',
  'EditNotSupported': '不支持编辑',
  'Add1': '添加',
  'ComponentDoesNotSupportSharing': '组件不支持分享',
  'OnBoard': '上架',
  'MoveToItem': '移动到项目',
  'FailPleaseContactAdministrator': '失败，请联系管理员',
  'MoveToMyFile2': '可至我的文件',
  'ViewingInTheProject': '项目中进行查看',
  'FailedToDeletePage': '删除页面失败',
  'GroupAs': '分组为',
  'ViewDown': '下查看',
  'MoveFailPleaseContactAdministrator': '移动失败，请联系管理员',
  'SelectAGroup': '请选择一个分组',
  'YouHaveToChooseAProject': '必须要选择一个项目',
  'PostLinksCopiedToClipboard': '发布链接已复制到剪贴板',
  'PublishFailPleaseContactAdministrator': '发布失败，请联系管理员',
  '3dScene': '三维场景',
  'DeterminesWhetherThisReleaseLinkShouldBePlacedNext': '是否确定下架此发布链接',
  'Discontinue': '已下架',
  'NameCannotBeEmpty2': '名称不能为空',
  'TheLibraryCantBeEmpty': '库分类不能为空',
  'PostedSuccessfully': '发布成功',
  'PleaseMoveToLibrary': '请移至库',
  'MainBody': '中查看',
  'UpdateSuccessful': '更新成功',
  'ThisOperationIsNotCurrentlySupportedForThisTheme':'该主题暂不支持此操作',
  'DeterminesThatAllContentsAreEmptied': '是否确定清空所有内容',
  'Question?': '？',
  'TheContentsWillBePermanentlyRemovedFromTheWastebin': '这些内容在回收站中将会被永久删除',
  'Clear2': '清空',
  'Already': '已',
  'JumpAddress': '跳转地址',
  'Jump': '跳转',
  'MovedSuccessfullyTo': '已成功移动到',
  'OperateFailPleaseContactAdministrator': '操作失败，请联系管理员',
  'Copy5': '副本',
  'Close': '关闭',
  'PageDeleteSuccessful': '页面删除成功',
  'PleaseSelectTheCorrectDate':'请选择正确日期',
  'MyFile2':'我的文件',
  'TheNameMustNotExceed50Words':'名称不得超过50个字',
  'AreYouSureYouWantToDeleteIt': '确定删除',
  'SaveTheCurrentScene':'保存当前场景',
}
export default zh_CN;
