import { createRouter, createWebHashHistory } from 'vue-router';
const main = () => import('../components/main.vue');
const content = () => import('../components/newContent/newContent.vue');
const routes = [{
  path: '/',
  name: "/",
  component: () => import('../components/Login')
}, {
  path: '/main/:projectUuid/:buildingUuId/:ticket',
  name: "/main2",
  component: main
}, {
  path: '/main',
  name: "/main",
  component: main
}, {
  path: '/content/:projectUuid',
  name: '/content/:projectUuid',
  component: content
}, {
  path: '/content',
  name: "/content",
  component: content
}, {
  path: '/login/:ticket',
  name: "/login",
  component: () => import('../components/Login')
}, {
  path: '/:pathMatch',
  component: () => import("../components/notFound.vue")
}];
const router = createRouter({
  history: createWebHashHistory(),
  routes
});
export default router;