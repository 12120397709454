import Cookies from 'js-cookie';
const TokenKey = 'Kingfisher-Token';
export function getToken() {
  let ticket = Cookies.get(TokenKey);
  if (!ticket) {
    ticket = localStorage.getItem('KC_TOKEN');
  }
  return ticket ? ticket : '';
}
export function setToken(token) {
  localStorage.setItem('KC_TOKEN', token);
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function getAuthParams() {
  return {
    authentication: getToken()
  };
}
export function getUser() {
  return localStorage.getItem('KC_USER');
}