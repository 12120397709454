import { ref } from 'vue';
const env = ref("designer");
const snapshot = ref(null);
const version = "5.1.0 BETA 20241014";
const updateTime = "2024-10-14";
const isDesigner = function () {
  return env.value === 'designer';
};
const isIde = function () {
  return env.value === 'ide';
};
const isPresenter = function () {
  return env.value === 'presenter';
};
const init = function () {
  if (window.location.href.indexOf("/presenter/") !== -1) {
    env.value = "presenter";
    console.log("初始化为查看器模式, 版本为:" + version);
  } else if (window.location.href.indexOf("/ide/") !== -1) {
    env.value = "ide";
    console.log("初始化为代码编辑器模式", version);
  } else {
    env.value = "designer";
    console.log("初始化为设计器模式", version);
  }
  if (isPresenter()) {
    /*window.console.log = () => {
    };
    window.console.warn = () => {
    };
    window.console.error = () => {
    };*/
  }
};
export default {
  env,
  isDesigner,
  isIde,
  isPresenter,
  init,
  snapshot,
  updateTime,
  version
};