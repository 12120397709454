import axios from 'axios';
import {ElMessage, ElNotification} from 'element-plus';
import router from "../router";
import {getToken, removeToken} from "@/utils/auth";

let _headers = {};
let HOST = "";
let serverTimeDelta = 0;

export let _handle = {
  redirect: {},
  showMsg: {}
};

/*
  Drone 2.0 请求服务
 */
class KingfisherService {

  constructor(param = {}) {
    this.needCrypt = window.location.href.indexOf("https://") === -1
    this.host = param.host || '';

    this.errorStatus = {
      message: '',
      statusCode: -1,
      screen: ''
    };

    this.context = "/cloud";
    if (param.context) {
      this.context = param.context;
    }
  }

  static setHeader(headers) {
    Object.keys(headers).forEach(key => {
      _headers[key] = headers[key];
    });
  }

  static hasHeader(key) {
    return !!_headers[key];
  }

  static get header() {
    return _headers;
  }

  static setHost(host) {
    HOST = host;
  }

  static get serverIP() {
    return HOST;
  }

  static setHandle(funcName, func) {
    _handle[funcName] = func;
  }

  static setServerTime(time) {
    serverTimeDelta = time - new Date().getTime();
  }

  getURL(url) {
    let result;
    // const isDev = 'development' === process.env.NODE_ENV;

    if (!url.startsWith("http")) {
      result = this.context + "/api" + url;
      // if (isDev) {
      //   result = this.context + result;
      // }
      //
      // let currUrl = window.location.href;
      // currUrl = currUrl.substr(currUrl.indexOf("://") + 3);
      // const contextPath = currUrl.substr(currUrl.indexOf("/"), currUrl.indexOf("cloud") - currUrl.indexOf("/"));
      // result = contextPath + result;
    } else {
      result = url;
    }

    return result;
  }

  sendFetch(method, args) {
    let data = args[1];
    if (this.needCrypt) {
      /*console.log(serverTimeDelta);
      if (data != null) {
        data = {
          content: window.Kf.kfSaveData(JSON.stringify(args[1])),
          timestamp: new Date().getTime() + serverTimeDelta
        }
      } else {
        data = {
          content: null,
          timestamp: new Date().getTime() + serverTimeDelta
        }
      }*/
    }
    let axOptions = {
      method: method,
      url: this.getURL(args[0]),
      data: data,
      headers: Object.assign(args[2] ? {} : {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authentication': getToken()
      }, args[2]),
    };
    if (args[3]) {
      Object.assign(axOptions, args[3]);
    }
    return axios(axOptions);
  }

  fetchPromise(method, args) {
    return new Promise((resolve, reject) => {
      // tools.trace(window.kcRequest && method === 'PUT', "发出请求");
      this.sendFetch(method, args).then(res => {
            // tools.trace(window.kcRequest && method === 'PUT', "收到结果", res);
            if (res.statusText === 'OK' || res.status === 200) {
              // resolve(res.data);
              const fileName = res.headers['content-disposition'];
              if (fileName) {
                let type = res.headers['content-type'];
                let name = res.headers['content-disposition'];
                name = name.substring(name.indexOf('=') + 1);
                let blob = new Blob([res.data], {type: type});
                let url = window.URL.createObjectURL(blob);
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                name=decodeURIComponent(name)
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                this.handleConnection(resolve, reject, Kf.t('DownloadSuccessful'));
              } else {
                this.handleConnection(resolve, reject, res.data);
              }
            } else {
              reject(this.processFailed(res.data));
            }
          }, err => {
            console.warn(this.getURL(args[0]));
            if (err.response) {
              reject(this.processFailed(err.response));
            } else if (typeof err.message === 'string') {
              reject(this.processFailed(err.message));
            } else {
              reject(this.processFailed(err.message.response || {message: Kf.t('NetworkError')}));
            }
          }
      ).catch(error => {
        // tools.trace(window.kcRequest, "出现错误", error);
        reject(this.processFailed(error));
      });
    });
  }

  handleConnection(resolve, reject, data) {
    // tools.trace(window.kcRequest, "数据", data);
    if (data && data.kfSysCode && typeof data.kfSysCode == 'number') {
      const _msg = data.kfSysMsg ? data.kfSysMsg : 'System Error';

      const _code = data.kfSysCode;
      let _errMsg = _msg;
      switch (_code) {
        case 101: {
          _errMsg = Kf.t('UserNotFound');
          break;
        }
        case 102: {
          _errMsg = Kf.t('IncorrectUsernameOrPassword');
          break;
        }
        case 103: {
          _errMsg = Kf.t('LoginTimedOut');
          break;
        }
        case 104: {
          _errMsg = Kf.t('YourAccountHasBeenEnteredInAnotherDeviceAndYouAreForced');
          break;
        }
        case 106: {
          _errMsg = Kf.t('LoginTimedOut');
          break;
        }
        case 200: {
          _errMsg = Kf.t('SsoLoginClosed');
          break;
        }
        case 201: {
          _errMsg = Kf.t('SsoUserNotFound');
          break;
        }
        case 202: {
          _errMsg = Kf.t('SsoUserOrPasswordInputError');
          break;
        }
        case 304: {
          _errMsg = Kf.t('WechatLoginFailUnknownService');
          break;
        }
        default: {
          _errMsg = Kf.t('SystemError');
          break;
        }
      }
      if (window.KcLastErrorTime == null || window.KcLastError == null
          || new Date().getTime() - window.KcLastErrorTime > 1000 || _errMsg != window.KcLastError) {
        window.KcLastErrorTime = new Date().getTime();
        window.KcLastError = _errMsg;
        ElNotification({
          title: 'Warning',
          message: _errMsg,
          type: 'warning'
        });
      }
      if (_code === 103 || _code === 104 || _code === 106) {
        removeToken();
        router.push({path: '/'});
      }
      reject(this.processFailed(_msg));
    } else {
      // tools.trace(window.kcRequest, "返回结果");
      resolve(data);
    }
  }

  processFailed(response) {
    if (typeof response === 'string') {
      this.errorStatus = {
        message: response,
        statusCode: null,
        screen: ''
      };
      return {response, errorStatus: this.errorStatus};
    }

    if (!response.status) {
      this.errorStatus = {
        message: response.message,
        statusCode: 0
      };
      if (process.env.PLATFORM === 'web') {
        ElMessage({
          type: 'warning',
          message: response.message,group:true
        });
      }
      return {response, errorStatus: this.errorStatus};
    }

    this.errorStatus = {
      message: '',
      statusCode: response.status,
      screen: ''
    };

    let message = "";
    switch (response.status) {
      case -1:
        this.errorStatus.message = response.message;
        return {response, errorStatus: this.errorStatus};
      case 0:
        ElMessage({
          type: 'warning',
          message: Kf.t('PleaseCheckNetworkConnection')
        });
        break;
      case 401:
        ElMessage({type: 'warning', message: Kf.t('LoginTimedOut')});
        router.push({path: '/'});
        break;
      default:
        message = '发送请求失败：' + JSON.stringify(response.data);
        if (response.data.requestId) {
          message = `[${response.data.requestId}] ${
              response.data.message
          }`;
        }

        if (process.env.PLATFORM === 'web') {
          ElMessage({type: 'warning', message: message});
        }

        this.errorStatus.message = message;
        return {response, errorStatus: this.errorStatus};
    }
  }

  get() {
    return this.fetchPromise('GET', arguments);
  }

  post() {
    return this.fetchPromise('POST', arguments);
  }

  put() {
    return this.fetchPromise('PUT', arguments);
  }

  delete() {
    return this.fetchPromise('DELETE', arguments);
  }

}

export default KingfisherService;