const zh_TW = {
  language: {
    'zh-CN': '简体中文',
    'zh-TW': '繁体中文',
    'en': 'English',
  },
  '2dConfigurationTool': '二維組態工具',
  '3d': '三維',
  '3dConfigurationTool': '三維組態工具',
  '3dDesigner': '三維設計器',
  '3dDisplay': '三維顯示',
  '?': '嗎?',
  'About': '關於',
  'AboutUs': '關於我們',
  'ABuildingWithTheSameNameAlreadyExists': '已存在相同名稱建築',
  'Add': '添加',
  'AddAttribute': '新增屬性',
  'AddDrawing': '添加圖紙',
  'AddNew': '新增',
  'AddPosition': '新增機位.',
  'AddSelection': '添選',
  'AddStatus': '新增狀態',
  'AdvancedOutlineSettings': '描邊高級設置',
  'AdvancedSettings': '高級設置',
  'Alignment': '對齊方式',
  'AlignUp': '上對齊',
  'All': '全部',
  'AllWidgets': '全部構件',
  'AllowClickUser': '允許點擊用戶',
  'AllowMovingToGroup': '允許移動到組',
  'AllowPropertySettings': '允許屬性設置',
  'AlongXAxis': 'X軸向',
  'AlongYaxis': 'Y軸向',
  'AlreadyAdded': '已經添加',
  'AmbientOcclusion': '環境光遮蔽',
  'AModelThatWasBoundBeforeTheBindingWasPerformed': '執行綁定前已綁定的模型',
  'Angle': '角度',
  'AnotherUserLoggedInWithTheSameAccount': '同賬號他人登錄',
  'Appearances': '方頭',
  'Area': '面積',
  'AreYouSure': '確定刪除?',
  'AreYouSureItsDeleted': '是否確定刪除?',
  'AreYouSureYouWantToDeleteIt': '確定刪除',
  'AreYouSureYouWantToDeleteThisData': '確定刪除此數據嗎?',
  'Arrange': '排布',
  'AttemptMapping': '嘗試映射',
  'Author': '作者',
  'AutomaticallyGeneratePoint': '自動生成點位',
  'AutomaticLayout': '自動佈局',
  'BaselineAlignment': '基線對齊',
  'BasicLegend': '基本圖列',
  'BaseHeight': '基高',
  'BatchBindingDevicePointInformation': '批量綁定設備點信息',
  'Bathroom': '衛生間',
  'Beijing': '北京',
  'BeijingKingfisherTechnologyCoLtd': '北京翠鳥智擎科技有限公司',
  'BetweenDevices': '設備間',
  'BindDevicePoints': '綁定設備點位',
  'BindModels': '綁定模型',
  'Blend': '混合',
  'Blur': '模糊',
  'BlurValue': '模糊值',
  'BottomAlignment': '下對齊',
  'Building': '建築',
  'BuildingHasBeenCreated': '已創建建築',
  'BuildingInformation': '建築信息',
  'BuildingList': '建築列表',
  'BuildingName': '建築名',
  'CacheHit': '命中緩存',
  'CalibrateDrawingCenter': '校準圖紙中心點',
  'CalibrateDrawingSize': '校準圖紙尺寸',
  'CalibrateFloorCenter': '校準樓層中心點',
  'CalibrateFloorSizes': '校準樓層尺寸',
  'Cancel': '取消',
  'Cancel2': '取消',
  'CancelClear': '放棄清空',
  'CancelDeletion': '放棄刪除',
  'CancelDeletion2': '取消刪除',
  'CannotBeEmpty': '不能為空',
  'CannotBeRenamed': '不能重名',
  'CannotBeMatched': '未匹配',
  'Category': '分類',
  'Category01': '分類01',
  'Category02': '分類02',
  'Category03': '分類03',
  'Category04': '分類04',
  'Category05': '分類05',
  'Category06': '分類06',
  'Category07': '分類07',
  'CapitalLetters': '大寫字母',
  'CenterHorizontally': '水平居中',
  'CenterPointOffset': '中心點偏移',
  'CentralAngle': '圓心角',
  'Centred': '居中',
  'Chair': '椅',
  'Chamfer': '倒角',
  'ChromaticAberration': '色散',
  'CircularGradient': '圓形漸變',
  'City': '城市',
  'Clear': '清空',
  'ClearAll': '是否清空所有?',
  'ClearCanvas': '清空畫布',
  'ClearScene': '場景清空',
  'ClickOkToConfirm': '確定',
  'ClickOkToConfirm2': '確 定',
  'ClickToSelect': '點擊選擇',
  'ClickToUpload': '點擊上傳',
  'Clone': '克隆',
  'CloneProperties': '克隆屬性',
  'CloningFailed': '克隆失敗',
  'CloningSuccessful': '克隆成功',
  'ColourBalance': '色彩均衡',
  'Colours': '顏色',
  'ColumnName': '列名',
  'Comma': '，',
  'Component': '組件',
  'ComponentCategory': '構件類',
  'ComponentLibrary': '組件庫',
  'ComponentMarket': '組件市場',
  'ComponentName': '組件名稱',
  'ConferenceRoom': '會議室',
  'Confirm': '確認',
  'ConfirmClear': '確認清空',
  'ConfirmDeletetion': '確認刪除',
  'ConfirmThatTheNewPasswordDoesNotMatchTheNewPassword': '確認新密碼與新密碼不匹配',
  'ConfirmThatTheNewPasswordIsNotEmpty': '確認新密碼不為空',
  'ContactUs': '聯繫我們',
  'Coordinates': '座標',
  'Copy': '副本',
  'Copy2': '複製',
  'CopyCode': '複製代碼',
  'CopySuccessful': '複製成功',
  'NoObjectsCoped':'沒有複製目標',
  'Corridor': '走廊',
  'Create': '創建',
  'CreateAGroup': '創建一個分組',
  'CreateANewSession': '創建新的會話',
  'CreateAProject': '創建一個項目',
  'CreateClassification': '創建分類',
  'CreateCopy': '創建副本',
  'Created': '創建時間',
  'CreateItem': '創建項目',
  'CreateOrder': '創建順序',
  'CreateBuilding': '創建建築',
  'CreateData': '創建數據',
  'CreatedSuccessful': '創建成功',
  'CreateNewPreset': '創建新的預設',
  'CreatePipeline': '創建管線',
  'CreateProject': '創建項目',
  'CreateRegion': '創建區域',
  'ContinuousCreation': '連續創建',
  'DrawPipeline': '管綫',
  'DrawRoute': '路綫',
  'CreateScene': '創建場景',
  'CreateWall': '創建牆體',
  'CreateWallFill': '創建填充牆體',
  'CreateWallNormal': '創建普通牆體',
  'CropContents': '裁剪內容',
  'Crossaxis': '交叉軸',
  'CurrentVersion': '當前版本',
  'Data': '數據',
  'Data1': '數據1',
  'DataBinding': '數據綁定',
  'DataConnectionFailed': '數據連接失敗',
  'DataLabel': '數據標籤',
  'DataNotAvailable': '暫無數據',
  'DataReceived': '收到數據',
  'DataStatus': '數據狀態',
  'Default': '默認',
  'Delete': '刪除',
  'Delete2': '是否刪除',
  'DeleteMapping': '刪除映射',
  'DeleteItAndFindItInTheWastebin': '刪除後可以在回收站中找到',
  'DepthOfField': '景深',
  'Description': '描述',
  'Desk': '書桌',
  'Device': '設備',
  'DeviceCategory': '設備類',
  'DeviceEncoding': '設備編碼',
  'DiagramEncoding': '圖紙編碼',
  'DiagramName': '圖元名稱',
  'Diagrams': '圖紙',
  'DiagramsNotSupported': '圖紙不支持',
  'Diameter': '直徑',
  'Dimensions': '尺寸',
  'Direction': '方向',
  'DirectionalLight': '方向光',
  'DisplayContents': '展示内容',
  'Display': '顯示',
  'DistributeHorizontalSpacingEvenly': '水平間距均分',
  'Divider': '隔線',
  'Door': '門',
  'Dotted': '點狀',
  'DottedLine': '虛線',
  'DoubleLine': '雙線',
  'Down': '下',
  'DownloadSuccessful': '下載成功',
  'Drag': '拖拽',
  'Drag&Copy': '拖拽複製',
  'DragScene': '拖動場景',
  'East': '華東',
  'Edit': '編輯',
  'EditData': '編輯數據',
  'EditIcon': '編輯圖標',
  'EditTable': '編輯表格',
  'ElectricityCategory': '電力類',
  'ElevatorLobby': '電梯廳',
  'EllipticalGradient': '橢圓漸變',
  'EmptyWastebin': '清空回收站',
  'Encoding': '編碼',
  'Endpoint': '端點',
  'EndpointCoordinates': '終點座標',
  'EndRadius': '末端半徑',
  'EnterAccountName': '填入賬戶名',
  'EnterAccountPassword': '填入賬戶密碼',
  'EnterANewPassword': '輸入新密碼',
  'EnterTheItemCodePlease': '請輸入項目編碼',
  'EnterTheOriginalPassword': '輸入原密碼',
  'EnergyConsumptionCategory': '能耗類',
  'Environment': '環境',
  'Equidistant': '等距',
  'EquilibriumOfSpaces': '間距均分',
  'ExportAs': '導出為',
  'Export': '導出',
  'ExitLogin': '退出登錄',
  'Failed': '失敗',
  'FailedToCopy': '複製失敗',
  'FailedToPublishVersion': '發佈版本失敗',
  'FailedToSendRequest': '發送請求失敗',
  'FanCategory': '風機類',
  'FastAntialiasing': '快速抗鋸齒',
  'File': '文件',
  'Fill': '鋪滿',
  'Fill2': '填充',
  'FillFieldDetailsHere': '此處填寫字段詳細內容',
  'Filter': '濾鏡',
  'FireLane': '消防通道',
  'FitContent': '適應內容',
  'Fixed': '定',
  'FixedHeight': '固定高度',
  'FixedScreen': '固定屏幕',
  'FixedWidth': '固定寬度',
  'Floor': '樓層',
  'Floor2': '地板',
  'FloorHeight': '層高',
  'FloorNotVisible': '樓層不可見',
  'FloorSelection': '樓層選擇',
  'Foundation': '基礎',
  'FullScrollbar': '完整滾動條',
  'FunctionalArea': '功能區',
  'FunctionTypeError': '函數類型錯誤',
  'Gas': '氣',
  'Glass': '玻璃',
  'Glow': '光暈',
  'Gradient': '漸變',
  'GradientWalls': '梯形牆體',
  'GroundGrid': '地面網格',
  'Grouping': '分組',
  'GroupName': '分組名稱',
  'Height': '高度',
  'Help': '幫助',
  'HelpDocument': '幫助文檔',
  'HemisphericCameraSurveillance': '半球機監控',
  'Hide': '隱藏',
  'Hint': '提示',
  'Horizontal': '橫向',
  'Horizontal2': '水平',
  'HorizontalScrollBar': '左右滾動條',
  'Icon': '圖標',
  'IgnoreMapping': '忽略映射',
  'Image': '圖片',
  'ImportPosition': '導入點位',
  'IncorrectUsernameOrPassword': '用戶或密碼輸入錯誤',
  'IndiviualElements': '個元素',
  'IndivualIcons': '個圖標',
  'InternalSpacing': '內間距',
  'InvalidInput': '無效輸入',
  'InverseColor': '反色',
  'IsPickable': '可拾取',
  'ItMayBeBecauseSomeoneElseHasLoggedIntoYourAccount': '可能是因為有其他人登錄了您的賬戶,',
  'Justify': '兩端對齊',
  'KeepFixedWhenScrolling': '滾動時保持固定',
  'KingfisherCloud2dConfigurationTool': '翠鳥雲二維組態工具',
  'KingfisherCloud3dConfigurationTool': '翠鳥雲三維組態工具',
  'KingfisherCloud3dDesigner': '翠鳥雲三維設計器',
  'KingfisherCloudComponentMarket': '翠鳥雲組件市場',
  'KingfisherClouds': '翠鳥雲',
  'KingfisherCloudSpaceGovernanceTool': '翠鳥雲空間治理工具',
  'Label': '標籤',
  'LanguageSwitching':'語言切換',
  'LastUpdateTime': '最後更新時間',
  'LayerInformation': '圖層信息',
  'Left': '左',
  'LeftAlign': '左對齊',
  'LeftAndRight': '左右',
  'LeftMouseButton': '鼠標左鍵',
  'LeisureArea': '休閒區',
  'Length': '長度',
  'LengthGreaterThan': '長度大於',
  'Library': '庫',
  'Lights': '燈',
  'LimitAndResize': '約束和調整大小',
  'LinearGradient': '線性漸變',
  'LineData': '條數據',
  'Liquid': '液體',
  'List': '列表',
  'Loadbearing': '承重',
  'LoadingDiagrams': '圖紙加載中...',
  'Lobby': '大廳',
  'Location': '位置',
  'Lock': '鎖定',
  'LoggedOutOfTheSystem': '已經登出系統',
  'Login': '登錄',
  'LoginCredentialsExpired': '登錄憑據失效',
  'LoginCredentialsNotFound': '未找到登錄憑據',
  'LoginTimedOut': '登錄超時',
  'Logout': '退出登錄',
  'LowercaseLettersAndSpecialCharacters': '小寫字母和特殊字符',
  'MainAxis': '主軸',
  'MaintainFixed': '保持固定',
  'Manual': '手動',
  'MapablePoint': '可被映射點位',
  'Mapped': '已映射',
  'MappingIsDisabled': '映射已經停用',
  'Maximum': '最大',
  'MergeWalls': '牆體合併',
  'Minimum': '最小',
  'MirroredObject': '鏡像物體',
  'MirrorLeft&Right': '左右鏡像',
  'MirrorUpAndDown': '上下鏡像',
  'Mode': '模式',
  'Model': '模型',
  'Models': '個模型',
  'ModelDragging': '模型拖拽',
  'ModelWithoutADeviceCode': '沒有設備碼的模型',
  'ModifiedSuccessfully': '修改成功',
  'Monochrome': '單色',
  'More': '更多',
  'Move': '移動',
  'MoveGroup': '移動分組',
  'Mulitply': '正片疊底',
  'MyFiles': 'My files',
  'Name': '名稱',
  'NameIsADuplicate': '名稱重複',
  'NameLength20Characters': '名稱長度20字符',
  'NameLength80Characters': '名稱長度80字符',
  'NetworkError': '網絡錯誤',
  'New': '新建',
  'NewColumn': '新列',
  'NewData': '新數據',
  'NewPasswordFormatError': '新密碼格式錯誤',
  'NewPasswordToIncludeNumbers': '新密碼需包含數字',
  'NoChangeInData': '數據未發生變化,',
  'NoChangeInData2': '數據未發生變化',
  'NoDataChangesNeedToBeSaved': '數據未變更，無需保存',
  'Noise': '噪點',
  'NoEditingAtPresent': '暫無編輯',
  'NonarrayTypeParameter': '非數組類型參數',
  'None': '無',
  'Normal': '普通',
  'NotSelected': '未選擇',
  'NumberOfFloors': '樓層數量',
  'ObtainData': '獲取數據',
  'Of': '的',
  'Office': '辦公室',
  'Official': '官方',
  'OfficialComponent': '官方組件',
  'OfficialContent': '官方内容',
  'OnlyFloor': '僅樓層',
  'Open': '打開',
  'Operation': '操作',
  'OperationSuccessful': '操作成功',
  'OrDidYouLogIntoTheSystemInAnotherBrowserWindows': '或者是您在其他瀏覽器窗口登錄了系統',
  'OuterSpacing': '外間距',
  'Overflow': '溢出',
  'ParametersAre': '參數是',
  'Parking': '停車',
  'PasswordCannotBeEmpty': '密碼不能為空',
  'Paste': '粘貼',
  'Path': '路徑',
  'Perspective': '視角',
  'Preview': '預覽',
  'PickUp': '拾取',
  'PointMode': '點',
  'LineMode': '綫',
  'ArcModel':'弧線',
  'AreaModel': '面',
  'Pipeline': '管線',
  'PipelineCategory': '管線類',
  'Placeholder': '佔位',
  'PleaseCheckNetworkConnection': '請檢查網絡連接',
  'PleaseEnterAnAccountNumber': '請輸入賬號',
  'PleaseEnterRequiredItem': '請輸入必選項',
  'PleaseEnterTheAuthor': '請輸入作者',
  'PleaseEnterThePassword': '請輸入密碼',
  'PleaseEnterTheProjectCode': '請輸入項目編碼',
  'PleaseEnterTheProjectName': '請輸入項目名稱',
  'PleaseFillInTheProjectName': '請填寫項目名',
  'PleaseRelogIn': '請重新登錄',
  'PleaseSelect': '請選擇',
  'PleaseSelectDataEntry': '請先選中一條數據',
  'PointsThatWereBoundBeforeTheBindingWasPerformed': '執行綁定前已綁定的點位',
  'PointType': '點位類型',
  'Position': '點位',
  'Preset': '預設',
  'PresetName': '預設名稱',
  'Project': '項目',
  'ProjectCode': '項目編碼',
  'ProjectDescription': '項目描述',
  'ProjectList': '項目列表',
  'ProjectManagement': '項目管理',
  'ProjectName': '項目名',
  'ProjectSettings': '項目設置',
  'Properties': '屬性',
  'PropertySettings': '屬性設置',
  'Proportional': '等比',
  'PublishVersion': '發佈版本',
  'Quantity': '數量',
  'RowQuantity': '行數量',
  'ColumnQuantity': '列數量',
  'Radius': '半徑',
  'RadicalGradient': '徑向漸變',
  'RectangularWalls': '矩形牆體',
  'RecentlyDeleted': '最近刪除',
  'RecentlyEdited': '最近編輯',
  'RecentlyUsed': '最近使用',
  'ReconfirmTheNewPassword': '再次確認新密碼',
  'Region': '地區',
  'Regional': '區域',
  'RegionSettings': '區域設置',
  'Request': '請求',
  'Rename': '重命名',
  'ReplaceModel': '替換模型',
  'RequestFailed': '請求失敗,',
  'RequestFailed2': '請求失敗',
  'RequestsForServices': '請求服務',
  'RequestSuccessful': '請求成功',
  'Resources': '資源',
  'ResponseSize': '響應大小',
  'RestoreVersion': '回退版本',
  'RestoreVersion2': '回滾版本',
  'Result': '結果',
  'ReturnProjectList': '返回項目',
  'Right': '右',
  'RightAllignment': '右對齊',
  'RightMouseButton': '鼠標右鍵',
  'Room': '房間',
  'Rotate': '旋轉',
  'RotateScene': '旋轉場景',
  'RoundCorner': '圓角',
  'RoundedCornerAdvancedSettings': '圓角高級設置',
  'RoundHead': '圓頭',
  'RouteConfiguration': '路由配置',
  'RunScene': '運行場景',
  'Save': '保存',
  'SaveAs': '另存為',
  'SaveAsName': '另存為名稱',
  'SaveFailed': '保存失敗',
  'SaveSceneAs': '場景另存為',
  'SaveSuccessfiul': '另存成功',
  'SavingPleaseWait': '正在保存中，請稍後',
  'ScatterPoints': '撒點',
  'Scene': '場景',
  'SceneName': '場景名稱',
  'SceneNotSaved': '場景未保存',
  'SceneNotSavedConfirmSwitchTo': '場景未保存，確定切換為',
  'SceneSettings': '場景設置',
  'ScreenSpaceReflection': '屏幕空間反射',
  'ScrollWheel': '鼠標滾軸',
  'Search': '搜索',
  'SearchModelName': '搜索模型名稱',
  'SelectDate&Time': '選擇日期時間',
  'SelectFile': '選取文件',
  'SelectIcon': '選擇圖標',
  'SelectPostprocessingEffect': '選擇一種後期效果',
  'Sensors': '傳感器',
  'Settings': '設置',
  'Shadow': '陰影',
  'Sharpen': '銳化',
  'Shear': '剪力',
  'ShortcutKeys': '快捷鍵',
  'Show': '顯示',
  'ShowAllGroups': '顯示全部分組',
  'ShowArea': '顯示面積',
  'ShowEncoding': '顯示編碼',
  'ShowName': '顯示名稱',
  'Single': '個',
  'SmartOrganize': '智能整理',
  'SolidLine': '實線',
  'Southcentral': '中南',
  'Space': '空間',
  'SpaceSurplus': '空間剩餘',
  'Spacing': '間距',
  'SpatialGovernanceTool': '空間治理工具',
  'SplitWalls': '牆體拆分',
  'SqlIs': 'SQL是',
  'SsoLoginClosed': 'SSO登錄已關閉',
  'SsoUserNotFound': 'SSO用戶未找到',
  'SsoUserOrPasswordInputError': 'SSO用戶或密碼輸入錯誤',
  'Stack': '堆疊',
  'Stairwell': '樓梯間',
  'StartingCoordinates': '起點座標',
  'StartingRadius': '起始半徑',
  'StartProcessingData': '開始處理數據',
  'StartQuickConstructionUsingInitialTemplateComponents': '利用初始模板組件開始快速搭建',
  'StartRequestingData': '開始請求數據',
  'Status': '狀態',
  'Straighten': '打直',
  'Strength': '強度',
  'StretchLeft&Right': '左右拉伸',
  'StretchUpAndDown': '上下拉伸',
  'Style1': '樣式一',
  'Style10': '樣式十',
  'Style11': '樣式十一',
  'Style12': '樣式十二',
  'Style13': '樣式十三',
  'Style14': '樣式十四',
  'Style15': '樣式十五',
  'Style16': '樣式十六',
  'Style2': '樣式二',
  'Style3': '樣式三',
  'Style4': '樣式四',
  'Style5': '樣式五',
  'Style6': '樣式六',
  'Style7': '樣式七',
  'Style8': '樣式八',
  'Style9': '樣式九',
  'Styles': '樣式',
  'Success': '成功',
  'SuccessfullyBinded': '成功綁定',
  'SucessfullyPublishedVersion': '發佈版本成功',
  'Surveillance': '監控',
  'Furniture': '傢具',
  'SystemError': '系統異常',
  'SystemErrorPleaseTryAgainLater': '系統異常，請稍後再試',
  'Table': '桌',
  'Tables': '的表',
  'TextLabel': '文字標籤',
  'TextPosition': '文字位置',
  'Texture1': '材質1',
  'TheCiphertextHasBeenCorruptedAndCannotBeResolved': '密文已被破壞，無法解析',
  'TheDataFormatMatchesSuccessfully': '數據格式匹配成功,',
  'TheDimensionIs': '維度是',
  'TheIndicatorsAre': '指標是',
  'ThemeSelect': '主題選擇',
  'TheNewPasswordCannotBeEmpty': '新密碼不能為空',
  'TheOldPasswordCantBeEmpty':  '原密碼不能為空',
  'Thickness': '厚度',
  'ThumbnailInformation': '圖元信息',
  'Timeout': '超時',
  'Tutorial': '教程',
  'To': '到',
  'Tools': '工具',
  'Tooltip': '氣泡提示',
  'Top': '頂點',
  'Transparency': '透明度',
  'Type': '類型',
  'Types': '種類型',
  'UmappablePoints': '不可被映射點位',
  'UnboundObject': '未綁定對象',
  'Unclassified': '未分類',
  'Undefined': '未定義',
  'UndoDeletion': '反刪除',
  'Unmapped': '未映射',
  'UnSave': '不保存',
  'Up': '上',
  'UpAndDown': '上下',
  'Update': '更新',
  'UpdateCad': '更新CAD',
  'UpdateDrawings': '更新圖紙',
  'UpdateFailed': '更新失敗',
  'UpdateScene': '更新場景',
  'UpdateTime': '更新時間',
  'Upload': '上傳',
  'UploadedSuccessfully': '上傳成功',
  'UploadedSuccessfullyEffectiveAfterRefresh': '上傳成功,刷新後可生效',
  'UploadFailed': '上傳失敗',
  'UploadToServer': '上傳到服務器',
  'User': '用戶',
  'UserAccountExpired': '用戶賬號已過期',
  'UserComponent': '用戶組件',
  'UserContents': '用戶內容',
  'UserDoesNotExist': '用戶不存在',
  'UserManual': '使用文檔',
  'Username': '用戶名',
  'UserNameCannotBeEmpty': '用戶名不能為空',
  'UsernameOrPasswordCannotBeEmpty': '用戶名或密碼不能為空',
  'UsernameOrPasswordIncorrect': '用戶名或者密碼錯誤',
  'UserNotFound': '用戶未找到',
  'UserNotRegistered': '用戶尚未註冊',
  'UserPrivillegeMissingPleaseContactAdministrator': '用戶權限不足，請聯繫管理員提升權限',
  'Value': '值',
  'Value1': '值1',
  'Value10': '數值',
  'Value2': '值2',
  'Value3': '值3',
  'VerificationFailed': '驗證失敗,',
  'Version': '版本',
  'VertexCoordinates': '頂點座標',
  'Vertical': '縱向',
  'Vertical2': '垂直',
  'VerticalCenter': '垂直居中',
  'VerticalScrollBar': '上下滾動條',
  'VerticalSpaceEquilibrium': '垂直間距均分',
  'Vignette': '暗角',
  'Visible': '可見',
  'Wall': '牆',
  'Wall2': '牆壁',
  'WallLength': '牆體長度',
  'WallSegment': '牆段',
  'Water': '水',
  'WebPage': '網頁',
  'WechatLogin': '微信登錄',
  'WechatLoginFailUnknownService': '微信登錄失敗，未知服務',
  'WellKeepYouFor30Days1': '，我們將為您保留30天。',
  'Wastebin': '回收站',
  'WastebinEmptiedSuccessfully': '回收站清空成功',
  'Width': '寬度',
  'Window': '窗',
  'Wire': '電',
  'XAxis': 'X軸',
  'XAxisGridlines': 'X軸隔線',
  'YAxis': 'Y軸',
  'YAxisGridlines': 'Y軸隔線',
  'YouHaveAlreadyLoggedOutOfTheSystem': '您已經登出系統,',
  'YouHaveNotObtainedAProductAuthorization': '您尚未獲取產品授權',
  'YouHaventOperatedForTooLong': '您因為太久沒有操作,',
  'YourAccountHasBeenEnteredElsewhereAndYouAreForcedOffline': '您的賬號已在其他環境登錄，您被迫下線',
  'YourAccountHasBeenEnteredInAnotherDeviceAndYouAreForced': '您的賬號已在其他設備登錄，您被迫下線',
  'Zoom': '縮放',
  'ZoomScene': '縮放場景',
  'Download': '下載',
  'PleaseSelectTheProjectAndSceneFirst':'請先選擇項目和場景',
  'Permissions':'權限',
  '3dComponent': '三維組件',
  'InitialCamera': '初始相機',
  'TriggerEvent': '觸發事件',
  'MultiscreenSynchronization': '多屏同步',
  'ScreenGroup': '屏幕組',
  'ScreenRoles': '屏幕角色',
  'BackgroundColor': '背景顏色',
  'BorderLineColor': '邊框綫顔色',
  'FullScreenControl': '全屏控制',
  'PostEffect': '後期',
  'CancelCapture':'取消捕獲',
  'Elevation':'高程',
  'ExportSpaceData':'導出空間數據',
  'CreateSpacePointTable':'生成空間點表',
  'Theme': '主題',
  '3DWindow':'3D視窗',
  'sideView':'側視圖',
  'frontView':'正視圖',
  'topView':'頂視圖',
  'morning':'上午',
  'noon':'中午',
  'dusk':'黃昏',
  'night':'夜晚',
  '2DWindow':'2D視窗',
  'enableAdsorbent':'開啓吸附',
  'Grid':'網格',
  'Object':'對象',
  'ObjectMode':'對象',
  'Path2': '路綫',
  'Statistics':'統計',
  'Partition': '隔斷',
  'Picture':'圖片',
  'Positioning':'定位',
  'Altitude':'海拔',
  'UserName':'用戶名稱',
  'Role':'角色',
  'PeriodOfValidity':'有效期',
  'AddUser':'添加用戶',
  'UserPrivillege':'用戶權限',
  'ForeverValid':'永久有效',
  'AreYouSureToDeleteTheUserPermission':'確定刪除該用戶權限嗎',
  'Sure':'確定',
  'ProjectAdministrator':'項目管理員',
  'Editor':'編輯者',
  'Viewers':'查看這',
  'Creator':'創建者',
  'AddedSuccessfully':'添加成功',
  'Restore': '恢復',
  'AfterConfirmation': '確定后',
  'TheContentWillBePermanentlyDeleted': '該内容將會被永久刪除',
  'DeletePermanently': '永久刪除',
  'DetermineWhetherToRestore': '是否確定恢復',
  'TheContentWillBeRestoredToItsOriginalPosition': '該内容將會被恢復到原來位置',
  'SetSpace': '設置空間',
  'AreYouSureYouWantToDeleteItDeleteItAndFindItInTheWastebin': '是否確定刪除？删除后可以在回收站内找到，我們將爲您保留',
  'ThirtyDays': '30天。',
  'FileDeleteSuccessful': '文件刪除成功',
  'FileRestorationSuccess': '文件恢復成功',
  'WastebinResourceListNetworkRequestError': '回收站資源列表網絡請求錯誤',
  'EmptyWastebinAttemptFailed': '回收站清空失敗',
  'CompanyName2': '企業名稱',
  'PhoneNumber2': '聯係電話',
  'Website': '網址',
  'ProductName': '產品名稱',
  'VersionNumber': '版本號',
  'EditIn': '編輯于',
  'PriorTo': '年之前',
  'DaysAgo': '天之前',
  'AnHourAgo': '小時之前',
  '#MinutesAgo': '分鐘之前',
  'TenSecondsAgo': '10秒之前',
  'ExportModel':'導出模型',
  'InformationAnomaly': '信息異常',
  'PleaseReenterAndTryAgain': '請重新嘗試登錄',
  'ImportComponent': '導入組件',
  'YouCanOnlyUploadIt': '只能上傳',
  'Documentation': '文件',
  'Batch':'批量',
  'SelectAll': '全選',
  'Selected1':'已選擇',
  'NotSelected1':'未選擇',
  'DeleteSuccess': '刪除成功',
  'ConfirmTheDeletion': '確認刪除嗎',
  'GoBackToTheHomepage': '返回主頁',
  'NameMayNotBeLongerThan50Bytes': '名稱長度不超過50個字節',
  'KeepRatio': '保持比例',
  'PleaseEnter': '请输入',
  'ItsBeenModifiedPleaseReland': '修改成功，请重新登陆',
  'UserSRemainingSpaceIsNotEnough': '用户剩余可用空间不足',
  'AreYouSureYouWantToSwitchLanguagesAndReload': '确定要切换语言并重新加载吗？',
  'ImportDirectly': '直接导入',
  'BestPerformance': '性能最优',
  'Balance': '平衡',
  'OptimalPerformance': '效果最优',
  'PleaseFillInTheSceneName': '请填写场景名称',
  'PleaseSelectTheItemFirst': '请先选择项目',
  'EmptyScene': '空场景',
  'SelectTheGroupOnTheLeft': '请选择左侧的分组',
  'ImportError':'导入失败',
  'ImportSuccess':'导入成功',
  'NewFloor': '新建楼层',
  'Today': '今天',
  'CouldNotUpload': '无法上传',
  'AddSuccess':'添加成功',
  'NameRepeat': '名称重复',
  'CreateSuccessful': '创建成功',
  'LimitedNumberOfProjects': '项目数量受限',
  'PleaseContactTheAdministrator': '请联系管理员',
  'UploadedByMe': '我上传的',
  'IAdded':'我添加的',
  'Deleted': '已删除',
  'DeletionFailed': '删除失败',
  'TheGroupAlreadyExists': '该分组已存在',
  'PleaseReenter': '请重新输入',
  'UpdateFailPleaseContactAdministrator': '更新失败，请联系管理员',
  'Cannot': '不可以',
  'Beginning': '开头',
  'MoveSuccessfully': '移动成功',
  'MoveSuccessfullyToViewUnderThisItem': '移动成功，可至该项目下进行查看',
  'CanBeMovedTo': '可移至',
  'CheckDown': '下进行查看',
  'Release': '发布',
  'Auto': '自动',
  'WidthFill': '宽度填充',
  'HeightFill': '高度填充',
  'Scale2': '比例缩放',
  'Screen': '屏風',
  'Yes': '是',
  'No': '否',
  'EditNotSupported': '不支持编辑',
  'Add1': '添加',
  'ComponentDoesNotSupportSharing': '组件不支持分享',
  'OnBoard': '上架',
  'MoveToItem': '移动到项目',
  'FailPleaseContactAdministrator': '失败，请联系管理员',
  'MoveToMyFile2': '可至我的文件',
  'ViewingInTheProject': '项目中进行查看',
  'FailedToDeletePage': '删除页面失败',
  'GroupAs': '分组为',
  'ViewDown': '下查看',
  'MoveFailPleaseContactAdministrator': '移动失败，请联系管理员',
  'SelectAGroup': '请选择一个分组',
  'YouHaveToChooseAProject': '必须要选择一个项目',
  'PostLinksCopiedToClipboard': '发布链接已复制到剪贴板',
  'PublishFailPleaseContactAdministrator': '发布失败，请联系管理员',
  '3dScene': '三维场景',
  'DeterminesWhetherThisReleaseLinkShouldBePlacedNext': '是否确定下架此发布链接',
  'Discontinue': '已下架',
  'NameCannotBeEmpty2': '名称不能为空',
  'TheLibraryCantBeEmpty': '库分类不能为空',
  'PostedSuccessfully': '发布成功',
  'PleaseMoveToLibrary': '请移至库',
  'MainBody': '中查看',
  'UpdateSuccessful': '更新成功',
  'ThisOperationIsNotCurrentlySupportedForThisTheme':'该主题暂不支持此操作',
  'DeterminesThatAllContentsAreEmptied': '是否确定清空所有内容',
  'Question?': '？',
  'TheContentsWillBePermanentlyRemovedFromTheWastebin': '这些内容在回收站中将会被永久删除',
  'Clear2': '清空',
  'Already': '已',
  'JumpAddress': '跳转地址',
  'Jump': '跳转',
  'MovedSuccessfullyTo': '已成功移动到',
  'OperateFailPleaseContactAdministrator': '操作失败，请联系管理员',
  'Copy5': '副本',
  'Close': '关闭',
  'PageDeleteSuccessful': '頁面刪除成功',
  'PleaseSelectTheCorrectDate':'請選擇正確日期',
  'MyFile2':'我的文件',
  'TheNameMustNotExceed50Words':'名稱不得超過50個字',
  'SaveTheCurrentScene':'保存當前場景',
}
export default zh_TW