import { getCurrentInstance, onMounted, ref } from 'vue';
import loadingModel from '@/model/loading';
import oemManager from '@/utils/oem';
export default {
  name: "loading",
  setup() {
    const logo = oemManager.getImage("product", "loading", "space");
    return {
      logo,
      progress: loadingModel.progress,
      show: loadingModel.show,
      showLogo: loadingModel.showLogo
    };
  }
};