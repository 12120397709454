import envModel from '@/model/env';
import { onMounted } from 'vue';
import Loading from '@/components/loading';
import oemManager from "@/utils/oem";
window.Kf = window.Kf || {};
window.Kf.prodCode = "ff2364a0be3d20e46cc69efb36afe9a5";
envModel.init();
export default {
  name: 'App',
  components: {
    Loading
  },
  setup() {
    window['kf-env'] = -1;
    window['kf-context-path'] = 'cloud';
    window.oncontextmenu = function (e) {
      e.preventDefault();
    };
    onMounted(() => {
      oemManager.dependPlatformType();
      const go = new window.Go();
      let mod, inst;
      WebAssembly.instantiateStreaming(fetch("static/kingfisher.wasm"), go.importObject).then(result => {
        mod = result.module;
        inst = result.instance;
        run();
      }).catch(err => {
        console.error(err);
      });
      async function run() {
        await go.run(inst);
        inst = await WebAssembly.instantiate(mod, go.importObject);
      }
    });
  }
};