import { nextTick, ref } from 'vue';
const show = ref(false);
const progress = ref(0);
let timer;
const showLogo = ref(false);
const showLoading = function (callback, duration, logoShow) {
  show.value = true;
  progress.value = 0;
  showLogo.value = false;
  if (logoShow) {
    showLogo.value = logoShow;
  }
  nextTick(callback);
  if (duration) {
    let interval = duration * 1000 / 100;
    timer = setInterval(() => {
      progress.value++;
      if (progress.value > 99) {
        progress.value = 99;
      }
    }, interval);
  }
  setTimeout(hideLoading, 60 * 1000);
};
const hideLoading = function () {
  clearInterval(timer);
  show.value = false;
  showLogo.value = false;
};
const updateProgress = function (value) {
  if (isNaN(value)) {
    value = 0;
  }
  if (value < 0) {
    value = 0;
  }
  if (value > 100) {
    value = 100;
  }
  progress.value = value;
};
export default {
  showLoading,
  hideLoading,
  updateProgress,
  show,
  progress,
  showLogo
};